import React, { useState, useEffect } from "react";
import classNames from "classnames";
// import { TextField } from "@mui/material";
import { Button } from "../components/Button/Button";
import { MarginCurrency } from "../interfaces/marginCurrency.interface"; // Adjust the import path as necessary
import { Wallet } from "../interfaces/wallet.interface"; // Adjust the import path as necessary
import { ParamsUser } from "../interfaces/paramsUser.interface"; // Adjust the import path as necessary
import { Params } from "../interfaces/params.interface"; // Adjust the import path as necessary
import { ethers } from "ethers";
import { eventCloud } from "../EventCloud";
import { handleWithdraw } from "../solidity/Withdraw"; // Import the withdrawal function

import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { pipe } from "fp-ts/lib/function";
import { zodResolver } from "@hookform/resolvers/zod";

import { TextField } from "../components/TextField/TextField";
import { Modal } from "../components/Modal/Modal";
import { ModalHeader } from "../components/Modal/ModalHeader";
import { CopyField } from "../components/CopyField/CopyField";

import { ReactComponent as IconRefresh } from "../assets/icons/svg/refresh.svg";
import { Balance } from "../interfaces/balance.interface";

import WithdrawalConfirmDialog from "./WithdrawalConfirmDialog"; 

import QRCode from 'qrcode.react';

interface DepositWithdrawBTCProps {
  open: boolean;
  action: string;
  walletBalance: string;
  selectedWallet: Wallet;
  selectedMarginCurrency: MarginCurrency;
  balances: Record<string, Balance>;
  onClose: () => void;
}

const useFormSchema = (direction: string) =>
  z.object({
    amount: z.string().min(1, "Required"),
    destinationAddress:
      direction === "withdraw"
        ? z.string().min(1, "Required")
        : z.string().optional(),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

const DepositWithdrawBTC: React.FC<DepositWithdrawBTCProps> = ({
  open,
  action,
  onClose,
  walletBalance,
  selectedWallet,
  selectedMarginCurrency,
  balances,
}) => {
  const [direction, setDirection] = useState(action);
  const [depositAddress, setDepositAddress] = useState("");
  const [paramsUser, setParamsUser] = useState<ParamsUser | null>(null);
  const [params, setParams] = useState<Params | null>(null);
  const [isWithdrawalConfirmDialogOpen, setIsWithdrawalConfirmDialogOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");

  useEffect(() => {
    // Subscribe
    const subscription = eventCloud.paramsStore.subscribe((params) => {
      setParams(params);
    });

    return () => {
        subscription.unsubscribe();
     };
  }, []);

  useEffect(() => {
    // Subscribe
    const subscription = eventCloud.paramsUserStore.subscribe((params) => {
      setParamsUser(params);
    });

    return () => {
        subscription.unsubscribe();
     };
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema(direction)),
  });

  const initiateWithdraw = async (
    amount: string,
    destinationAddress: string
  ) => {
    console.log(
      `[initiateWithdraw] amount=${amount} destinationAddress=${destinationAddress}`
    );

    if (!params) return;

    const baseToken = eventCloud.getMarginCurrencyObject().token_address;

    // Define the parameters for the withdrawal
    const token = baseToken; // Replace with actual token address
    const nonce = Date.now(); // Unique nonce for each transaction
    const feeWithdrawal = (getTransactionFee() * 1e10).toFixed(0);
    const contractAddress = eventCloud.params.base_contract;

    try {
      const withdrawalDetails = await handleWithdraw({
        token,
        amount,
        destinationAddress,
        nonce,
        feeWithdrawal,
        contractAddress,
      });

      onClose();
    } catch (error) {
      console.error("Withdrawal failed:", error);
    }
  };


    const handleGenerateDepositAddress = () => {
        switch  (selectedMarginCurrency.token_address) {
            case "0x0000000000000000000000000000000000000002":
                eventCloud.requestBtcAddress();
                break;

            case "0x0000000000000000000000000000000000000024":
                eventCloud.requestTronAddress();
                break;

            case "0x0000000000000000000000000000000000000028":
                eventCloud.requestSolanaAddress();
                break;
        }   
        
    }

  const handleWithdrawMax = () => {
    console.log(`[handleWithdrawMax] balances`, balances);

    if (!selectedMarginCurrency) return;  
    const formattedMax = formatAmount(!balances[selectedMarginCurrency?.token_address] ? 0 : Number(balances[selectedMarginCurrency?.token_address]?.available_balance), selectedMarginCurrency.decimals);

    setValue('amount', formattedMax); 
  }

    const getDepositAddress = (): string => {
        switch (selectedMarginCurrency.token_address) {
            case "0x0000000000000000000000000000000000000002":
                return paramsUser?.btc_deposit_address ?? "";

            case "0x0000000000000000000000000000000000000024":
                return paramsUser?.tron_deposit_address ?? "";

            case "0x0000000000000000000000000000000000000028":
                return paramsUser?.solana_deposit_address ?? "";

            case "0x0000000000000000000000000000000000000029":
                return params?.ton_deposit_address ?? "";
        }

        return "";
    }

    const getTransactionFee = (): number => {
        //console.log("[getTransactionFee] ", params?.tron_transaction_fee);
        switch (selectedMarginCurrency.token_address) {
            case "0x0000000000000000000000000000000000000002":
                return Number(params?.btc_transaction_fee)

            case "0x0000000000000000000000000000000000000024":
                return (Number(params?.tron_transaction_fee)*1e8)*2

            case "0x0000000000000000000000000000000000000028":
                return Number(params?.solana_transaction_fee)

            case "0x0000000000000000000000000000000000000029":
                //console.log("Ton transaction fee: ", params?.ton_transaction_fee);
                return Number(params?.ton_transaction_fee)/10;
        }

        return 0
    }

    const handleCloseWithdrawalConfirmDialog = () => {
        console.log("[handleCloseWithdrawalConfirmDialog]");
        setIsWithdrawalConfirmDialogOpen(false);
    }

    const handleConfirmWithdrawalConfirmDialog = () => {
        initiateWithdraw(amount, destinationAddress)
    }

    const getWithdrawlaFee = (): number => {
        return getTransactionFee(); 
    }
  

  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => {
          console.log("Handle submit", data);
          console.log("direction", direction);
          if (direction === "withdraw") {
            //initiateWithdraw(data.amount, data.destinationAddress!);
            setAmount(data.amount);
            setDestinationAddress(data.destinationAddress!);
            setIsWithdrawalConfirmDialogOpen(true);
          } 
        })}
        className="relative"
      >
        <div className="sm:-mt-5 -mt-1">
          <ModalHeader onClose={onClose}>
            {direction == "deposit" ? "Deposit" : "Withdraw"} {selectedMarginCurrency.symbol} [{selectedMarginCurrency.network}]
          </ModalHeader>
        </div>

        <div className="flex pt-1 pb-4">
          <label className="w-1/2">
            <input
              type="radio"
              name="direction"
              value="Deposit"
              checked={direction === "deposit"}
              onChange={() => setDirection("deposit")}
              className="invisible absolute"
            />
            <div
              className={classNames(
                "w-full h-10 flex items-center justify-center cursor-pointer box-border text-center px-4 py-2 text-xs text-gray-150 hover:text-white disabled:text-gray-450 bg-gray-750 hover:bg-gray-740 border border-gray-650",
                { "border-b-2 border-b-white": direction === "deposit" }
              )}
            >
              DEPOSIT
            </div>
          </label>

          <label className="w-1/2 -ml-px">
            <input
              type="radio"
              id="limit"
              name="direction"
              value="Withdraw"
              checked={direction === "withdraw"}
              onChange={() => setDirection("withdraw")}
              className="invisible absolute"
            />
            <div
              className={classNames(
                "w-full h-10 flex items-center justify-center cursor-pointer box-border text-center px-4 py-2 text-xs text-gray-150 hover:text-white disabled:text-gray-450 bg-gray-750 hover:bg-gray-740 border border-gray-650",
                { "border-b-2 border-b-white": direction === "withdraw" }
              )}
            >
              WITHDRAW
            </div>
          </label>
        </div>
        {direction == "deposit" && getDepositAddress() != ""  && (
          <div>
            <div className="w-full flex justify-center mt-2 mb-4">
              <div className="bg-white">
                <QRCode value={getDepositAddress() || ""} size={200}  level={"Q"} />
              </div>
            </div>

            <div>
              <CopyField 
                courier
                text={getDepositAddress() || ""}
                label={selectedMarginCurrency.network+" Address"}
              />
            </div>

            <div className="mt-4">
              <CopyField 
                courier
                text={selectedWallet.address}
                label={"Comment/Memo"}
              />
            </div>

            <div className="text-xxs text-gray-450 pt-4 pb-2 mt-2">
                {selectedMarginCurrency.network != "Ton" && (
                    <div>
                        This is your deposit address. <br />
                        All <span className="text-yellow-550">{selectedMarginCurrency.symbol} [{selectedMarginCurrency.network}]</span> sent to this address will be credited to your account.<br /><br />
                    </div>
                ) }

                {selectedMarginCurrency.network == "Ton" && (
                    <div>
                        This is your deposit address and personal code. Make sure to <span className="text-red-550">properly complete the comment/memo field</span> of your TON transfer. If you forget to complete the comment/memo field, you will have to
                        contact support for manual assignment of your deposit.<br /><br />
                        All <span className="text-yellow-550">{selectedMarginCurrency.symbol} [{selectedMarginCurrency.network}]</span> sent using these instructions will be credited to your account.<br /><br />
                    </div>
                )}
                
              <span className="text-red-550">*</span>There are no deposit fees.<br /><br />
              <span className="text-red-550">*</span>After confirmation it will take up to 5 minutes for the deposit to appear in your balance.

            </div>
          </div>
        )}

        {direction == "withdraw" && (
          <div>
            <div className="text-xs text-gray-450 pb-3">
              Available: <strong className="text-gray-150">{formatAmount(!balances[selectedMarginCurrency?.token_address] ? 0 : Number(balances[selectedMarginCurrency?.token_address]?.available_balance), selectedMarginCurrency.decimals)}{" "}
              {selectedMarginCurrency.symbol} [{selectedMarginCurrency.network}]</strong>

              <Button
                type="button"
                variant="gray"
                size="small"
                onClick={handleWithdrawMax}
                className="ml-2"
              >
                MAX
              </Button>
            </div>
            <div className="w-full mb-5">
              <TextField
                {...register("amount")}
                error={errors?.amount}
                label={"Amount"}
                required
              />
            </div>

            <div className="w-full mb-5">
              <TextField
                {...register("destinationAddress")}
                error={errors?.destinationAddress}
                label={selectedMarginCurrency.symbol+" ["+selectedMarginCurrency.network+"] Destination Address"}
                required
                courier
                setValue={(text) => {
                  setValue("destinationAddress", text);
                }}
              />
            </div>
            <div className="py-1">
              <div className="flex text-xxs pb-1">
                <div className="text-gray-450">Withdrawal Fee:</div>
                <div className="ml-auto text-gray-150">{formatTransactionFee(getTransactionFee())} {selectedMarginCurrency.symbol} [{selectedMarginCurrency.network}]</div>
              </div>
            </div>
          </div>
        )}

        <div className="pt-4">
          {direction == "withdraw" && (
            <Button
              variant="yellow"
              size="large"
              // onClick={initiateWithdraw}
              className="w-full"
            >
              Withdraw {selectedMarginCurrency.symbol} [{selectedMarginCurrency.network}]
            </Button>
          )}

          {direction == "deposit" && getDepositAddress() == "" && (
            <>
              <div className="text-xxs text-gray-450 pt-4 pb-2 text-center">
                Press the button below to <br />
                Generate your <span className="text-yellow-550">{selectedMarginCurrency.symbol} [{selectedMarginCurrency.network}]</span> deposit address. <br /><br />
              </div>
              <Button
                variant="gray"
                size="large"
                type="button"
                onClick={handleGenerateDepositAddress}
                className="w-full"
                // iconLeft={
                //   <IconRefresh
                //     className={classNames("w-3 mr-2", { "animate-spin": false })}
                //   />
                // }
              >
                <span>Generate <span className="text-yellow-550">{selectedMarginCurrency.symbol} [{selectedMarginCurrency.network}]</span> Deposit Address</span>
              </Button>
            </>
          )}
        </div>
      </form>

        <Modal visible={isWithdrawalConfirmDialogOpen} onClose={handleCloseWithdrawalConfirmDialog} freeToClose>
            <WithdrawalConfirmDialog 
                open={isWithdrawalConfirmDialogOpen}
                onClose={handleCloseWithdrawalConfirmDialog}
                onConfirm={handleConfirmWithdrawalConfirmDialog}
                marginCurrency={selectedMarginCurrency}
                amount={formatAmount(Number(amount)*1e18, Math.min(8, selectedMarginCurrency.token_decimals))}
                feeWithdrawal={(getWithdrawlaFee()/1e8).toFixed(Math.min(8, selectedMarginCurrency.token_decimals))}
                netAmount={formatAmount((Number(amount)*1e18-Number(getWithdrawlaFee())*1e10), Math.min(8, selectedMarginCurrency.token_decimals))}
                destinationAddress={destinationAddress}                
            />
        </Modal>
    </div>
  );
};

const formatTransactionFee = (fee: number): string => {
  return (fee/1e8).toFixed(6);
};

const formatAmount = (amount: number, decimals: number): string => {
  return (Number(amount) / 1e18).toFixed(decimals);
};

export default DepositWithdrawBTC;
