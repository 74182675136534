import React, { useEffect, useState } from "react";
import classNames from "classnames";

export const Tooltip = ({
  label,
  text,
  position,
  underColor = "border-gray-450", 
  noUnderline = false,
  maxWidth = "200px", // Default maximum width
  minWidth = "200px", // Default minimum width
  children,
}: {
  label?: string;
  text: string;
  position?: "left" | "right";
  underColor?: string;
  noUnderline?: boolean;
  maxWidth?: string;
  minWidth?: string;
  children?: React.ReactNode;
}) => {
  const [id, setId] = useState<string>();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setId((Math.random() + 1).toString(36).substring(2));
  }, []);

  return (
    <span className="relative" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      {label && (
        <span
          id={id}
          className={classNames(
            "tooltip-label hover:cursor-help",
            !noUnderline ? "border-b border-dashed" : "",
            underColor // Use the passed color prop
          )}
        >
          {label}
        </span>
      )}
      {showTooltip && (
        <div
          className={classNames(
            "absolute top-0 translate-y-5 bg-gray-660 px-2 py-0.5 text-gray-450 z-50 text-justify",
            { 'right-0': position === 'right', 'left-0': position !== 'right' }
          )}
          style={{ maxWidth: maxWidth, minWidth: minWidth, whiteSpace: "normal" }} // Setting maximum width and normal white-space
        >
          {text}
        </div>
      )}
      {children}
    </span>
  );
};
