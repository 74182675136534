// Positions.tsx

import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import { eventCloud } from "./EventCloud";
import { Position } from "./interfaces/position.interface";
import { MarginCurrency } from "./interfaces/marginCurrency.interface";
import { Asset } from "./interfaces/asset.interface";
import { Balance } from "./interfaces/balance.interface";
import classNames from "classnames";
import { Button } from "./components/Button/Button";
import { Tooltip } from "./components/Tooltip";

import UpdateMarginDialog from "./dialogs/UpdateMarginDialog";
import TakeProfitStopLossDialog from "./dialogs/TakeProfitStopLossDialog";
import "./css/Positions.css";
import { Modal } from "./components/Modal/Modal";

// const pp: Position[] = [];
// for (let i = 0; i < 20; i++) {
//   pp.push({
//     tx_hash: `tx_hash_${i}`,
//     hash: `hash_${i}`,
//     base_token: `base_token_${i}`,
//     asset: `asset_${i}`,
//     side: Math.random() > 0.5,
//     size: `32423252523242325252`,
//     value: `32423252523242325252`,
//     entry_price: `32423252523242325252`,
//     mark_price: Math.random() * 100,
//     last_price: Math.random() * 100,
//     liquidation_price: `liquidation_price_${i}`,
//     leverage: Math.floor(Math.random() * 10) + 1,
//     margin: `margin_${i}`,
//     unrealized_pnl: `unrealized_pnl_${i}`,
//     realized_pnl: `realized_pnl_${i}`,
//     funding_rate: `funding_rate_${i}`,
//     decimals: 2,
//     amount_dec: 2,
//     margin_dec: 2,
//     margin_symbol: `$`,
//     profit: `profit_${i}`,
//     loss: `loss_${i}`,
//     funding_cost: `funding_cost_${i}`,
//     ur_funding_cost: `ur_funding_cost_${i}`,
//     created_at: new Date().toISOString(),
//     liquidated: Math.random() > 0.5,
//   });
// }

const Positions = () => {
  const [positions, setPositions] = useState<Position[]>([]);
  const [isUpdateMarginDialogOpen, setIsUpdateMarginDialogOpen] = useState(false);
  const [isTakeProfitStopLossDialogOpen, setIsTakeProfitStopLossDialogOpen] = useState(false);
  const [isTakeProfit, setIsTakeProfit] = useState(false);
  const [selectedPositionHash, setSelectedPositionHash] = useState("");
  const [selectedPositionMargin, setSelectedPositionMargin] = useState("");
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(
    null
  );
  const [selectedUpnl, setSelectedUpnl] = useState(0);
  const [selectedRpnl, setSelectedRpnl] = useState(0);
  const [userFRMultiplier, setUserFRMultiplier] = useState(1);
  const [marginCurrencies, setMarginCurrencies] = useState<
    Record<string, MarginCurrency>
  >({});

    const [balances, setBalances] = useState<Record<string, Balance>>({});
    useEffect(() => {
        const subscription = eventCloud.balancesStore.subscribe((newBal) => {
            //console.log("[Balances] newBal=", newBal);
            setBalances(newBal);
        });

        // Clean up the subscription
        return () => subscription.unsubscribe();
    }, []);

  useEffect(() => {
    if (eventCloud.paramsUserStore) {
      const parmasSub = eventCloud.paramsUserStore.subscribe((params) => {
        if (params) {
          setUserFRMultiplier(Number(params.fr_multiplier));
        }
      });

      return () => {
        parmasSub.unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    // Subscribe
    const subscription = eventCloud.positionsStore.subscribe((positions) => {
      if (!positions || positions.length === 0) {
        setPositions([]); // Return early if newAssets is empty
        return;
      }
      setPositions(positions);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (eventCloud.marginCurrenciesStore) {
      const currencySubscription = eventCloud.marginCurrenciesStore.subscribe(
        (currencies) => {
          //console.log("[Positions] marginCurrenciesStore ", currencies);
          if (currencies) {
            setMarginCurrencies(currencies);
          }
        }
      );

      return () => {
        currencySubscription.unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    const handleNewMarkPrice = (markPrice: any) => {
      //console.log("[handleNewMarkPrice] ", markPrice);
      setPositions((currentPositions) => {
        return currentPositions.map((position) => {
          if (position.asset === markPrice.symbol) {
            // Update mark_price for the matched position
            return { ...position, mark_price: Number(markPrice.price) };
          }
          return position; // Return the position as is if no match
        });
      });
    };

    const unsubscribeNewMarkPrice = eventCloud.on(
      "newMarkPrice",
      handleNewMarkPrice
    );

    const handleNewTrade = (trade: any) => {
      //console.log("[handleNewTrade] ", trade);
      setPositions((currentPositions) => {
        return currentPositions.map((position) => {
          if (position.asset === trade.Asset) {
            // Update mark_price for the matched position
            return { ...position, last_price: trade.Price };
          }
          return position; // Return the position as is if no match
        });
      });
    };

    const unsubscribeNewTrade = eventCloud.on("newTrade", handleNewTrade);

    return () => {
      unsubscribeNewMarkPrice();
      unsubscribeNewTrade();
    };
  }, []);

  useEffect(() => {
    const handleAssetUpdate = (updatedAssets: Asset[]) => {
      //console.log("[handleAssetUpdate] updatedAssets", updatedAssets)
      setPositions((currentPositions) => {
        return currentPositions.map((position) => {
          const matchingAsset = updatedAssets.find(
            (asset) => asset.symbol === position.asset
          );
          if (matchingAsset) {
            // Update funding_rate for the matched position
            return { ...position, funding_rate: matchingAsset.funding_rate };
          }
          return position; // Return the position as is if no match
        });
      });
    };

    const assetUpdateSubscription =
      eventCloud.assetsStore.subscribe(handleAssetUpdate);

    return () => {
      assetUpdateSubscription.unsubscribe();
    };
  }, []);

  const handleMarketClose = (position: Position) => {
    console.log("[handleMarketClose] ", position);

    navigateToAsset(position.asset);
    eventCloud.emit("changeMarginCurrency", position.base_token);
    eventCloud.emit("marketClose", position);
  };

  const navigateToAsset = (symbol: string) => {
    const asset = eventCloud.findAsset(symbol);
    if (!asset) return;
    const selectedAsset = eventCloud.selectedAsset.getValue();
    if (selectedAsset?.symbol != asset.symbol) {
      eventCloud.setSelectedAsset(asset);
    }
  };

  const handleUpdateMargin = (position: Position) => {
    //console.log("[handleUpdateMargin] ", position, upnl, rpnl);
    setSelectedPosition(position);
    setIsUpdateMarginDialogOpen(true);
  };

  const handleCancelUpdateMargin = () => {
    setIsUpdateMarginDialogOpen(false);
  };

  const handleConfirmUpdateMargin = () => {
    setIsUpdateMarginDialogOpen(false);
  };

    const handleTakeProfitStopLoss = (position: Position, isTakeProfit: boolean) => {
        console.log("[handleTakeProfitStopLoss] ", position);

        setIsTakeProfit(isTakeProfit);
        setSelectedPosition(position);
        setIsTakeProfitStopLossDialogOpen(true);


    };

    const handleCancelTakeProfitStopLoss = () => {
        setIsTakeProfitStopLossDialogOpen(false);
    };

    const handleConfirmTakeProfitStopLoss = (price: number, amount: number) => {
        setIsTakeProfitStopLossDialogOpen(false);

        console.log(`[handleConfirmTakeProfitStopLoss] isTakeProfit=${isTakeProfit} price=${price} amount=${amount}`);
        if (!selectedPosition) return;
        navigateToAsset(selectedPosition.asset);
        eventCloud.emit("changeMarginCurrency", selectedPosition.base_token);
        eventCloud.emit("takeProfitStopLoss", {position: selectedPosition, isTakeProfit, price, amount});
    };

    const handleAssetSymbolClick = (position: Position) => {
        console.log("[handleAssetSymbolClick] ", position);

        navigateToAsset(position.asset);
    };

    const handlePositionSizeClick = (position: Position) => {
        console.log("[handlePositionSizeClick] ", position);

        navigateToAsset(position.asset);
        eventCloud.emit("setOrderboxAmount", Number(position.size)/1e8);
    };

  return (
    <div className="text-xxs positions-block-wrapper">
      <h2 className="px-2 py-2 bg-black bg-opacity-30 border-gray-650 font-medium text-xs table-headline">
        Positions
        <span className="text-yellow-550"> [{positions.length}]</span>
      </h2>
      <div className="order-table-wrapper container-scroll">
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            minWidth: "1600px",
          }}
          className="order-table"
        >
          <thead>
            <tr>
              <th className="text-left" style={{ width: '50px' }}>
                <Tooltip
                  label="Asset"
                  text="Traded asset"
                />
              </th>
              <th className="text-right" style={{ width: '80px' }}>
              	<Tooltip
                  label="Size"
                  text="Size of the position in asset terms"
                />
              </th>
              <th className="text-right"  style={{ width: '140px' }}>
              	<Tooltip
                  label="Value"
                  text="Size x Price"
                />
              </th>
              <th className="text-right" style={{ width: '120px' }}>
              	<Tooltip
                  label="Entry Price"
                  text="Average entry price"
                />
              </th>
              <th className="text-right" style={{ width: '120px' }}>
              	<Tooltip
                  label="Last Price"
                  text="Last DMEX traded price for asset"
                />
              </th>
              <th className="text-right" style={{ width: '120px' }}>

              	<Tooltip
                  label="Mark Price"
                  text="Last Price-Source traded price for asset"
                />
              </th>
              <th className="text-right" style={{ width: '120px' }}>
              	<Tooltip
                  label="Margin Call"
                  text="At this price your position will be automatically market closed"
                />
              </th>
              <th className="text-right width-leverage" style={{ width: '70px' }}>
              	<Tooltip
                  label="Leverage"
                  text="Leverage based on current margin"
                />
              </th>
              <th className="text-right" style={{ width: '240px' }}>
              	<Tooltip
                  label="Margin"
                  text="Position Margin - Funding Cost + Unrealized Profit/Loss"
                />
              </th>
              <th className="text-right" style={{ width: '170px' }}>
              	<Tooltip
                  label="Unrealized PNL"
                  text="Unrealized Profit/Loss"
                />
              </th>
              <th className="text-right" style={{ width: '150px' }}>
              	<Tooltip
                  label="Realized PNL"
                  position="right"
                  text="Realized Profit/Loss Including Funding Cost"
                />
              </th>
              {/*<th className="text-right" style={{ width: '150px' }}>
              	<Tooltip
                  label="FUNDING COST"
                  text="Realized funding cost on the position"
                />
              </th>*/}
              <th className="text-right" style={{ width: '100px' }}>              
              	<Tooltip
                  label="FUNDING %"
                  position="right"
                  text="Current Funding Rate for Asset / 1H"
                />
              </th>
              <th className="text-left" style={{ width: '200px' }}>
                ACTIONS
                {/*<Tooltip
                  label="Action"
                  text="The traded pair for the position"
                  position="right"
                />*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {positions.map((position, index) => {
              const upnl = calcUPnL(position, marginCurrencies);
              const rpnl = calcRPnL(position);

              position.unrealized_pnl = upnl;
              position.realized_pnl = rpnl;

              const network_slug = eventCloud.findMarginCurrency(position.base_token)?.network_slug;

              return (
                <tr key={index}>
                  <td className="cursor-pointer select-none" onClick={() => { handleAssetSymbolClick(position) }}>{position.asset.replace(/usd/g, "").toUpperCase()}</td>
                  <td
                    className={classNames("cursor-pointer", [
                      position.side
                        ? "text-green-550  mr-4 text-xxs text-right"
                        : "text-red-550  mr-4 text-xxs text-right",
                    ])}
                    onClick={() => { handlePositionSizeClick(position) }}
                  >
                    {formatNumLocale(Number(position.size)/1e8, position.amount_dec)}
                  </td>
                  <td className="text-right">
                    {formatPrice(Number(position.value), 2)} USD
                  </td>
                  <td className="text-right text-yellow-550">
                    {formatPrice(
                      Number(position.entry_price),
                      position.decimals
                    )}{" "}
                    USD
                  </td>
                  <td className="text-right">
                    {formatPrice(
                      Number(position.last_price),
                      position.decimals
                    )}{" "}
                    USD
                  </td>
                  <td className="text-gray-450 text-right">
                    {formatPrice(Number(position.mark_price)*1e8,position.decimals)} USD
                  </td>
                  <td className="text-right text-blue-550">
                    {formatPrice(
                      Number(position.liquidation_price),
                      position.decimals
                    )}{" "}
                    USD
                  </td>
                  <td className="text-right">
                    {calcLeverage(
                        position, 
                        marginCurrencies, 
                        !position.is_cross 
                            ? 0
                            : Number(balances[position.base_token]?.crossmargin_profit)/1e10
                        )
                    }x

                    
                  </td>
                  <td className="text-right">
                    {formatNumLocale(
                        !position.is_cross 
                            ?   (Number(position.margin) +
                                //Number(position.funding_cost) +
                                // Number(position.profit) -
                                // Number(position.loss) -
                                //Number(position.ur_funding_cost)+
                                Number(upnl)) / 1e8
                            :   (Number(position.margin))/1e8
                        ,
                        position.margin_dec
                    )}
	                  	{/*<Tooltip
	                  		label={formatNumLocale(
				                    	!position.is_cross 
				                      		?	(Number(position.margin) +
				                        		//Number(position.funding_cost) +
				                        		// Number(position.profit) -
				                       	 		// Number(position.loss) -
				                        		//Number(position.ur_funding_cost)+
                                                Number(upnl)) / 1e8
				                        	: 	(Number(position.margin))/1e8
				                        ,
				                      	position.margin_dec
				                    )}
	                  		text={"LOCKED CROSS MARGIN: "+formatAmount(Number(position.cross_margin), position.margin_dec)+" "+position.margin_symbol+" ["+network_slug+"]" +
                                  " | CROSS MARGIN PROFIT: "+formatAmount(Number(balances[position.base_token]?.crossmargin_profit)/1e10, position.margin_dec)+" "+position.margin_symbol+" ["+network_slug+"]"  }
                            position="right"
                            noUnderline
	                	/>*/}
                    {" "}
                    {position.margin_symbol}{" "}
                    [{network_slug}]{" "}

                   
                  	

                    {position.is_cross && (
                    	<span className="text-yellow-550">{" "}[CROSS]{" "}</span>
                    )}   

                    <Button
                      type="button"
                      variant="gray"
                      size="tiny"
                      onClick={() => handleUpdateMargin(position)}
                    >
                      +
                    </Button>              
                    
                  </td>
                  <td
                    className={classNames([
                      Number(upnl) >= 0
                        ? "text-green-550  mr-4 text-xxs text-right"
                        : "text-red-550  mr-4 text-xxs text-right",
                    ])}
                  >
                    {formatNumLocale(Number(formatAmount(upnl, position.margin_dec)), position.margin_dec)}
                        {/*<Tooltip
                            label={formatNumLocale(Number(formatAmount(upnl, position.margin_dec)), position.margin_dec)}
                            text={convertToUsd(Number(formatAmount(upnl, position.margin_dec)), 4, position, marginCurrencies)+" USD"}
                            noUnderline
                        />*/}

                        {" "}
                        {position.margin_symbol}{" "}
                        [{network_slug}]
                  </td>
                  <td
                    className={classNames([
                      Number(rpnl) >= 0
                        ? "text-green-550  mr-4 text-xxs text-right"
                        : "text-red-550  mr-4 text-xxs text-right",
                    ])}
                  >
                    {formatNumLocale(Number(formatAmount(rpnl, position.margin_dec)), position.margin_dec)}
                        {/*<Tooltip
                            label={formatNumLocale(Number(formatAmount(rpnl, position.margin_dec)), position.margin_dec)}
                            text={"USD VALUE: "+convertToUsd(Number(formatAmount(rpnl, position.margin_dec)), 4, position, marginCurrencies)+" USD | Funding Cost: "+formatNumLocale(Number(formatAmount(
                                      Number(position.funding_cost) +
                                        Number(position.ur_funding_cost),
                                      4
                                    )), 4)+" "+position.margin_symbol+" ["+network_slug+"]"}
                            noUnderline
                            position="right"
                        />*/}
                    {" "}
                    {position.margin_symbol}{" "}
                    [{network_slug}]
                  </td>
                  {/*<td className="text-right text-red-550">
                    -
                    <Tooltip
                            label={formatNumLocale(Number(formatAmount(
                                      Number(position.funding_cost) +
                                        Number(position.ur_funding_cost),
                                      position.margin_dec
                                    )), position.margin_dec)}
                            text={convertToUsd(Number(formatAmount(
                                      Number(position.funding_cost) +
                                        Number(position.ur_funding_cost),
                                      position.margin_dec
                                    )), position.margin_dec, position, marginCurrencies)+" USD"}
                            noUnderline
                        />
                    {" "}
                    {position.margin_symbol}{" "}
                    [{network_slug}]
                  </td>*/}
                  <td className="text-right">
                    {formatFundingRate(
                      Number(position.funding_rate) * userFRMultiplier
                    )}
                    %
                  </td>
                  <td className="text-left">
                    <Button
                      type="button"
                      variant="gray"
                      size="tiny"
                      onClick={() => handleTakeProfitStopLoss(position, true)}
                    >
                      TAKE PROFIT
                    </Button>
                    <Button className="ml-1"
                      type="button"
                      variant="gray"
                      size="tiny"
                      onClick={() => handleTakeProfitStopLoss(position, false)}
                    >
                      STOP LOSS
                    </Button>
                    <Button className="ml-1"
                      type="button"
                      variant="gray"
                      size="tiny"
                      onClick={() => handleMarketClose(position)}
                    >
                      CLOSE
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {positions.length === 0 && (
          <div
            className="py-3 text-center text-xxs text-gray-450 h-full pb-8 flex justify-center items-center"
            style={{ height: "80%" }}
          >
            No data
          </div>
        )}
      </div>

      {/* Cancel Order Dialog */}
      <Modal
        visible={isUpdateMarginDialogOpen}
        onClose={handleCancelUpdateMargin}
        freeToClose
      >
        {selectedPosition && (
          <UpdateMarginDialog
            isOpen={isUpdateMarginDialogOpen}
            onCancel={handleCancelUpdateMargin}
            position={selectedPosition}
            onConfirm={handleConfirmUpdateMargin}
          />
        )}
      </Modal>

      <Modal
        visible={isTakeProfitStopLossDialogOpen}
        onClose={handleCancelTakeProfitStopLoss}
        freeToClose
      >
        {selectedPosition && (
          <TakeProfitStopLossDialog
            isOpen={isTakeProfitStopLossDialogOpen}
            onCancel={handleCancelTakeProfitStopLoss}
            position={selectedPosition}
            onConfirm={handleConfirmTakeProfitStopLoss}
            asset={selectedPosition.asset.replace(/usd/g, "").toUpperCase()}
            isTakeProfit={isTakeProfit}
          />
        )}
      </Modal>

      {/*<UpdateMarginDialog
        isOpen={isUpdateMarginDialogOpen}
        onCancel={handleCancelUpdateMargin}
        positionHash={selectedPositionHash}
        onConfirm={handleConfirmUpdateMargin}
        currentMargin={selectedPositionMargin}
      />*/}
    </div>
  );
};

const convertToUsd = (value: number, decimals: number, position: Position, marginCurrencies: Record<string, MarginCurrency>): string => {
    return formatNumLocale(value*Number(marginCurrencies[position.base_token]?.mark_price), decimals)
}

const calcUPnL = (
  position: Position,
  marginCurrencies: Record<string, MarginCurrency>
): number => {
  //console.log("[calcUPnL] ", side, entryPrice, lastPrice, amount)
  var upnl = 0;

  if (position.side) {
    upnl =
      (Number(position.last_price) - Number(position.entry_price)) *
      Number(position.size);
  } else {
    upnl =
      (Number(position.entry_price) - Number(position.last_price)) *
      Number(position.size);
  }

  return (
    upnl / 1e8 / Number(marginCurrencies[position.base_token]?.mark_price) -
    Number(position.ur_funding_cost)
  );
};

const calcRPnL = (position: Position): number => {
  var rpnl =
    Number(position.profit) -
    Number(position.loss) -
    Number(position.funding_cost);

  return rpnl;
};

const formatPrice = (price: number, decimals: number): string => {
  //return (Number(price) / 1e8).toFixed(decimals);
  return formatNumLocale(Number(price) / 1e8, decimals);
};

const calcLeverage = (
  position: Position,
  marginCurrencies: Record<string, MarginCurrency>,
  cross_profit: number,
): string => {
  return (
    Math.abs(Number(position.value) /
    (   Number(position.margin) +
        // Number(position.ur_funding_cost) +
        Number(position.profit) -
        Number(position.loss) +
        (! position.is_cross 
            ? (Number(calcUPnL(position, marginCurrencies)) - Number(position.funding_cost))
            : cross_profit)
    ) /
    Number(marginCurrencies[position.base_token]?.mark_price)
  )).toFixed(2);
};

const calcMultiplier = (mark_price: number): string => {
  //console.log("[calcMultiplier] mark_price=", mark_price);
  // Convert markPrice to a BigNumber
  const lastPrice = new BigNumber(mark_price);

  // Divide 1 by lastPrice
  const one = new BigNumber(1);
  let result = one.dividedBy(lastPrice);

  // Multiply result by 1e8
  const tenMillion = new BigNumber(1e8);
  result = result.multipliedBy(tenMillion);

  // Convert result to an integer
  const multiplier = result.integerValue(BigNumber.ROUND_FLOOR);

  //console.log("Multiplier:", multiplier.toString());
  return multiplier.toString();
};

const formatLeverage = (leverage: number): string => {
  return (Number(leverage) / 1e8).toFixed(0);
};

const formatAmount = (amount: number, amount_dec: number): string => {
  return (Number(amount) / 1e8).toFixed(amount_dec);
};

const formatFundingRate = (fr: any): string => {
  return Number(fr / 1e16).toFixed(6);
};

const formatNumLocale = (price: number, decimals: number): string => {
    return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(price);
};

export default Positions;
