// MarginCurrencies.tsx

import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { eventCloud } from "./EventCloud"; // Adjust the import path as necessary
import { Balance } from "./interfaces/balance.interface";
import { Params } from "./interfaces/params.interface";
import { MarginCurrency } from "./interfaces/marginCurrency.interface";

import { ReactComponent as IconChevron } from "./assets/icons/svg/chevron.svg";
import { Modal } from "./components/Modal/Modal";
import { ModalHeader } from "./components/Modal/ModalHeader";
import { Tooltip } from "./components/Tooltip";
import { ReactComponent as IconArrow } from "./assets/icons/svg/arrow.svg";

import { LinkIcon } from "./components/LinkIcon/LinkIcon";

import AvalancheLogo from "./assets/icons/avalanche.png";
import BitcoinLogo from "./assets/icons/bitcoin.png";
import EthereumLogo from "./assets/icons/ethereum.png";
import TronLogo from "./assets/icons/tron.png";
import PolygonLogo from "./assets/icons/polygon.png";
import DmexLogo from "./assets/icons/dmex.png";
import OptimismLogo from "./assets/icons/optimism.png";
import ArbitrumLogo from "./assets/icons/arbitrum.png";
import BaseLogo from "./assets/icons/base.png";
import BscLogo from "./assets/icons/bsc.png";
import SolanaLogo from "./assets/icons/solana.png";
import TonLogo from "./assets/icons/ton.png";

import { calculateTxFee } from "./helpers/fee"
import { getExplorer } from "./helpers/getExplorer"



interface VariantClassNames {
  [key: string]: { background: string; border: string };
}

const variantClassNames: VariantClassNames = {
  green: {
    background: "rgba(80, 173, 63, 1)",
    border: "1px solid rgba(80, 173, 63, 1)",
  },
  greenActive: {
    background: "rgba(80, 173, 63, 1)",
    border: "1px solid rgba(80, 173, 63, 1)",
  },
  red: {
    background: "rgba(234, 51, 46, 1)",
    border: "1px solid rgba(84, 18, 16, 1)",
  },
  redActive: {
    background: "rgba(234, 51, 46, 1)",
    border: "1px solid rgba(234, 51, 46, 1)",
  },
};

const MarginCurrencies = () => {
    const [marginCurrencies, setMarginCurrencies] = useState<Record<string, MarginCurrency>>({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [balances, setBalances] = useState<Record<string, Balance>>({});
    const [params, setParams] = useState<Params | null>(null);
    const [selectedMarginCurrency, setSelectedMarginCurrency] = useState<MarginCurrency | null>(eventCloud.getMarginCurrencyObject());

    const [selectedCurrency, setSelectedCurrency] = useState<
      string | "0x0000000000000000000000000000000000000003"
    >(
      localStorage.getItem("selectedCurrency") ||
        "0x0000000000000000000000000000000000000003"
    );
  
    useEffect(() => {
        // Subscribe
        const subParams = eventCloud.paramsStore.subscribe((params) => {
            setParams(params);
        });

        const subMarginCurrencies = eventCloud.marginCurrenciesStore.subscribe((data) => { 
            setSelectedMarginCurrency(eventCloud.getMarginCurrencyObject());
            setMarginCurrencies(data);            
        });

        const subBalances = eventCloud.balancesStore.subscribe((balances) => {
            setBalances(balances);
        });

        const unsubscribeChangeMarginCurrency = eventCloud.on(
            "changeMarginCurrency",
            onSelectCurrency
        );

        return () => {
            subParams.unsubscribe();
            subMarginCurrencies.unsubscribe();
            subBalances.unsubscribe();
            unsubscribeChangeMarginCurrency();
        };

    }, []);

    const onSelectCurrency = (currency: string) => {
        console.log("[MarginCurrencies: onSelectCurrency]", currency);
        setSelectedCurrency(currency);
        eventCloud.setSelectedMarginCurrency(currency); // Assuming this method updates the eventCloud state
    };

    const getCurrencyBalance = (tokenAddress: string) => {
    //console.log("getCurrencyBalance", tokenAddress)

        if (eventCloud.balancesStore.getValue()[tokenAddress]) {
            return eventCloud.balancesStore.getValue()[tokenAddress].total_balance;
        }
        return "0";
    };

    if (!params) return null;

    const getNetworkLogo = (network: string) => {
        let netIcon = DmexLogo;
        switch (network) {
            case "Ethereum":
                netIcon = EthereumLogo;
                break;

            case "Tron":
                netIcon = TronLogo;
                break;

            case "Polygon":
                netIcon = PolygonLogo;
                break;

            case "Bitcoin":
                netIcon = BitcoinLogo;
                break;

            case "Avalanche":
                netIcon = AvalancheLogo;
                break;

            case "Optimism":
                netIcon = OptimismLogo;
                break;

            case "Arbitrum":
                netIcon = ArbitrumLogo;
                break;

            case "Base":
                netIcon = BaseLogo;
                break;

            case "BSC":
                netIcon = BscLogo;
                break;

            case "Solana":
                netIcon = SolanaLogo;
                break;

            case "Ton":
                netIcon = TonLogo;
                break;
        }

        return netIcon;
    }

    return (
        <div className="pl-0.5 sm:pl-3">
            <button
                type="button"
                onClick={() => {
                    setIsDialogOpen(true);
                }}
                className={classNames(
                    "flex px-3 items-center w-full sm:w-64 h-8 bg-opacity-20 border text-gray-150 border-opacity-20 ease-out duration-100 hover:bg-opacity-40 hover:border-opacity-40",
                    [
                        selectedMarginCurrency?.network === "Testnet"
                        ? "bg-green-550 border-green-550"
                        : "bg-red-550 border-red-550",
                    ]
                )}
            >
                <div className="text-left">
                    <div className="text-xxs">Margin Currency</div>
                        <div className="text-xs font-semibold -mt-1">
                            {selectedMarginCurrency?.symbol}
                        </div>
                    </div>

                    <div className="ml-auto flex items-center">
                        <div className="text-right">
                            <div className="text-xxs ">Network</div>
                            <div className="text-xs font-medium -mt-1">
                                {selectedMarginCurrency?.network}
                            </div>
                        </div>
                    <div className={classNames("h-2 w-px ml-3 mr-2 bg-white bg-opacity-20")} />

                    <IconChevron className="w-3 text-gray-150" />
                </div>
            </button>

            <Modal
                visible={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false);
                }}
                freeToClose
                className="px-2 sm:px-4 sm:max-w-584"
            >
                <div className="sm:-mt-5 -mt-1">
                    <ModalHeader onClose={() => { setIsDialogOpen(false); }}>
                        SELECT MARGIN CURRENCY
                    </ModalHeader>
                    <div className="flex  text-xxxs text-right ml-1 mb-1">
                        CHAINS: 
                        <span className="flex"><img src={BitcoinLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />BTC</span>
                        <span className="flex"><img src={EthereumLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />ETH</span>
                        <span className="flex"><img src={TronLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />TRON</span>
                        <span className="flex"><img src={BscLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />BSC</span>
                        <span className="flex"><img src={PolygonLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />POLYGON</span>
                        <span className="flex"><img src={AvalancheLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />AVAX</span>
                        <span className="flex"><img src={OptimismLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />OP</span>
                        <span className="flex"><img src={ArbitrumLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />ARB</span>
                        <span className="flex"><img src={BaseLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />BASE</span>
                        <span className="flex"><img src={SolanaLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />SOLANA</span>
                        <span className="flex"><img src={TonLogo} className="w-3 h-3 ml-2 mr-1" alt="NetworkLogo" />TON</span>
                        
                    </div>
                    <div className="flex  text-xxxs text-right ml-1 mb-1">
                        <IconArrow className="w-2" />/<IconArrow className="w-2 rotate-180" />
                        : CURRENT DEPOSIT/WITHDRAW GAS FEE [USD]
                        <LinkIcon className="w-3 h-3 text-gray-150 ml-3" />
                        : LINK TO EXPLORER     
                    </div>

                    <div className="pt-1 container-scroll" style={{ height: '460px', maxHeight: '460px' }}>
                        <ul className="flex flex-wrap items-center">
                            {Object.values(marginCurrencies)
                                .sort((a, b) => a.order - b.order) // Sort the margin currencies by their order
                                .map((item) => {
                                
                                if (item.hidden) return;

                                const netIcon = getNetworkLogo(item.network);
                                const explorer = getExplorer(item.network);
                                const deposit_fee = calculateTxFee(item, params, "deposit");  
                                const withdraw_fee = calculateTxFee(item, params, "withdraw");  
                                //console.log("MAP ITEM: ", item, deposit_fee, withdraw_fee);
                                return (
                                    <li
                                        key={item.order}
                                        className={classNames("shrink-0 text-xs m-1 min-w-28 sm:min-w-32")}
                                    >
                                        <label
                                            className={classNames(
                                                "px-1 sm:px-3 py-1 cursor-pointer border hover:bg-opacity-40 ease-out duration-100",
                                                [
                                                    item.network != "Testnet"
                                                        ? "bg-red-550 border-red-550"
                                                        : "bg-green-550 border-green-550",
                                                    selectedCurrency == item.token_address
                                                        ? "bg-opacity-80 border-opacity-60"
                                                        : "bg-opacity-20 border-opacity-20",
                                                ]
                                            )}
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                checked={selectedCurrency == item.token_address}
                                                onChange={() => {
                                                    setIsDialogOpen(false);
                                                    onSelectCurrency(item.token_address);
                                                }}
                                                onClick={() => {
                                                    setIsDialogOpen(false);
                                                }}
                                                className="invisible absolute"
                                            />

                                            <div className="flex items-center">
                                                {/*<div className="w-3 h-3 bg-white flex items-center justify-center mr-3">
                                                    {selectedCurrency == item.token_address && (
                                                        <div
                                                            className={classNames("w-2 h-2", [
                                                                item.network != "Testnet"
                                                                    ? "bg-red-550"
                                                                    : "bg-green-550",
                                                            ])}
                                                        />
                                                    )}
                                                </div>*/}


                                                <div className="text-left flex-grow">
                                                    <div className="flex justify-between">
                                                        <span>
                                                            <div className="text-gray-150">
                                                                <Tooltip
                                                                    label={item.network != "Testnet" ? item.symbol : item.symbol+" [TEST]"}
                                                                    position={(item.order % 4 === 0) ? "right" : "left"}
                                                                    text={item.symbol+ " ON "+item.network}
                                                                    underColor="border-gray-150"                                                                    
                                                                />
                                                            </div>                                                            
                                                        </span>
                                                        <span className="flex">
                                                            {item.network != "Testnet" && (<img src={netIcon} className="w-4 h-4 mr-1" alt="NetworkLogo" />)}
                                                            {item.token_address != "0x0000000000000000000000000000000000000003" && (
                                                                <a target="_blank" href={item.token_contract_address == "0x0000000000000000000000000000000000000000" 
                                                                                        ? getExplorer(item.network)
                                                                                        : getExplorer(item.network) +
                                                                                                    (item.network == "Tron" 
                                                                                                    ? "token20/"
                                                                                                    : "token/") +
                                                                                                    item.token_contract_address}>
                                                                    <LinkIcon className="w-4 h-4 text-gray-150 ml-1" />
                                                                </a>
                                                            )}                                                            
                                                            
                                                        </span>
                                                    </div>
                                                    <div className="flex mb-1 text-xs">
                                                        <Tooltip
                                                            label={formatAmount(getCurrencyBalance(item.token_address), item.decimals)}
                                                            position={(item.order % 4 === 0) ? "right" : "left"}
                                                            text="BALANCE ON DMEX"
                                                        />
                                                    </div>
                                                    
                                                    {/*<div className="flex justify-between text-xxxs">
                                                        <Tooltip
                                                            label="DEPOSIT" 
                                                            position={(item.order % 4 === 0) ? "right" : "left"}
                                                            text="CURRENT DEPOSIT FEE IN USD"
                                                        />
                                                        <Tooltip
                                                            label="WITHDRAW" 
                                                            position={(item.order % 4 === 0) ? "right" : "left"}
                                                            text="CURRENT WITHDRAWAL FEE IN USD"
                                                        />
                                                        
                                                    </div>*/}
                                                    <div className="flex justify-between text-xxs">
                                                        
                                                        <span className="text-right flex">
                                                            <IconArrow className="w-3" />
                                                            <Tooltip
                                                                label={item.network != "Testnet" 
                                                                            ? deposit_fee.usd_fee < 0.01 
                                                                                ? "0.01"
                                                                                : formatFee(deposit_fee.usd_fee, 2)
                                                                            : "--"
                                                                        }
                                                                position={(item.order % 4 === 0) ? "right" : "left"}
                                                                text="DEPOSIT GAS FEE IN USD"
                                                            />
                                                            
                                                        </span>
                                                        <span className="text-right flex">
                                                            <IconArrow className="w-3 rotate-180" />
                                                            <Tooltip
                                                                label={item.network != "Testnet" 
                                                                            ? withdraw_fee.usd_fee < 0.001 
                                                                                ? "0.01"
                                                                                : formatFee(withdraw_fee.usd_fee, 2) 
                                                                            : "--"
                                                                        } 
                                                                position={(item.order % 4 === 0) ? "right" : "left"}
                                                                text="WITHDRAWAL GAS FEE IN USD"
                                                            />
                                                            
                                                            
                                                        </span>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </label>
                                    </li>
                                )
                            })}    
                        </ul>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const formatAmount = (amount: string, decimals: number): string => {
  return (Number(amount) / 1e18).toFixed(decimals);
};

const formatFee = (fee: number, decimals: number): string => {
  return fee.toFixed(decimals);
};

export default MarginCurrencies;
