// Header.tsx

import React, { useState, useEffect } from "react";
import { ReactComponent as IconLogo } from "./assets/icons/svg/logo.svg";
import { ReactComponent as DocsIcon } from "./assets/icons/svg/docs.svg";
import { ReactComponent as SupportIcon } from "./assets/icons/svg/support.svg";
import { ReactComponent as TelegramIcon } from "./assets/icons/svg/telegram.svg";
import MarginCurrencies from "./MarginCurrencies";
import Balances from "./Balances";
import Wallets from "./Wallets";
import { eventCloud } from "./EventCloud";
import { News } from "./interfaces/news.interface";
import "./css/Header.css";
import classNames from "classnames";
import { Tooltip } from "./components/Tooltip";

export const Header = () => {
  const [newsStore, setNewsStore] = useState<News[]>([]);

  useEffect(() => {
    // Subscribe
    const subscription = eventCloud.newsStore.subscribe((news) => {
      setNewsStore(news);
    });

    // Cleanup
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <header className="flex flex-wrap items-center bg-black text-white uppercase">
        <div className="px-2 w-20 sm:px-4 border-r border-gray-650 sm:w-26 h-12 flex justify-center items-center header_logo">
          <IconLogo className="w-16 sm:w-20" />
        </div>
        
        
        
        <a
          href="https://docs.dmex.app/"
          target="_blank"
          className="flex justify-center items-center px-2 h-12 border-r border-gray-650 text-xs text-gray-150 hover:text-white ease-in duration-100 header_link1"
        >
          
          <Tooltip
            position="left"
            maxWidth="200px"
            minWidth="50px"
            underColor="border-gray-450"
            text="documentation"
          >
            <DocsIcon className="w-7" />
          </Tooltip>
        </a>
        <a
          href="https://dmexhelp.freshdesk.com/support/home"
          target="_blank"
          className="flex justify-center items-center px-2  h-12 border-r border-gray-650 text-xs text-gray-150 hover:text-white ease-in duration-100 header_link2"
        >
          
          <Tooltip
            position="left"
            maxWidth="200px"
            minWidth="50px"
            underColor="border-gray-450"
            text="support"
          >
            <SupportIcon className="w-7" />
          </Tooltip>
        </a>

        <a
          href="https://t.me/dmex_official"
          target="_blank"
          className="flex justify-center items-center px-2 h-12 border-r border-gray-650 text-xs text-gray-150 hover:text-white ease-in duration-100 header_link3"
        >
           <Tooltip
            position="left"
            maxWidth="200px"
            minWidth="50px"
            underColor="border-gray-450"
            text="telegram"
          >
            <TelegramIcon className="w-7" />
          </Tooltip>
        </a>

        <div className="MarginCurrencies-container">
          <MarginCurrencies />
        </div>

        <div className="NoLiquidations-container -mt-1">
          <span className="ml-4 text-xs text-gray-450">            
            <Tooltip
              label="NO LIQUIDATIONS [?]"
              position="right"
              maxWidth="300px"
              minWidth="300px"
              underColor="border-gray-450"
              text="When a position reaches its margin call price, it is closed at the market price (AUTO STOP-LOSS). Remaining collateral is returned to the user balance."
            />
          </span>
        </div>

        <div className="Balances-container ml-auto mr-4">
          <Balances />
        </div>
        <div className="Wallets-container w-full border-t sm:border-t-0 sm:w-60 sm:border-l border-gray-650 pl-1 pr-2 sm:px-4 text-left h-12 flex items-center">
          <Wallets />
        </div>
        <div className="header-break-column-1380-wallet" />
        <div className="header-break-column-800" />
      </header>
      <div className="flex py-1 width-full text-xxs text-yellow-550 bg-gray-750 border-t border-b border-gray-650 overflow-x-hidden news-carousel-wrapper" style={{ height: '26px', minHeight: '26px' }}>
        <div className="news-carousel" style={{ minWidth: `100000px` }}>

          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-650 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span> 
              
              <a  className={classNames("ml-2 ", [
                news.internal 
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const calculateTimeAgo = (timestamp: string): string => {
  const currentDate = new Date().getTime(); // Convert to number
  const pastDate = new Date(timestamp).getTime(); // Convert to number

  const timeDifference = currentDate - pastDate;
  const minutes = Math.floor(timeDifference / (1000 * 60));

  if (minutes < 60) {
    // Less than an hour ago
    return(`${minutes}M`);
  } else if (minutes < 24 * 60) {
    // Less than a day ago
    const hours = Math.floor(minutes / 60);
    return(`${hours}H`);
  } else {
    // Days ago
    const days = Math.floor(minutes / (24 * 60));
    return(`${days}D`);
  }
};

