import React from "react";
import classNames from "classnames";

import { ReactComponent as IconDot } from "../../assets/icons/svg/switch-dot.svg";

type SwitchFieldProps<T> = {
  onChange: (value: T | undefined) => void;
  value: T | undefined;
  options: { value: T; label: string }[];
  label: string;
  withManualInput?: boolean;
  minValue?: number;
};

export const SwitchField = ({
  onChange,
  value,
  label,
  options,
  withManualInput,
  minValue= 1,
}: SwitchFieldProps<number>) => {
  return (
    <div className="overflow-hidden -mx-2">
      <div className="text-xxs text-gray-150 pb-2 mb-0.5 pl-2">{label}</div>

      {/*<div className="h-0.5 bg-gray-650 w-full" />*/}
      <div className="flex justify-between relative">
        <div className="flex justify-between relative flex-1  select-none">
          {options.map((o, i) => (
            <div
              key={o.value}
              onClick={() => {
                onChange(o.value);
              }}
              className={classNames(
                "text-xxs relative flex flex-1 flex-col items-center cursor-pointer switch-item-line ",
                {
                  fill: value && o.value < value,
                  active: o.value === value,
                }
              )}
            >
              {/*<div className='absolute -top-1 w-full h-0.5 bg-white' />*/}
              {o.value === value && (
                <div className="switch-item-line_dot">
                  <IconDot className="switch-item-line_dot_icon  -ml-0.5" />
                </div>
              )}
              <div
                className="switch-item-line-label -ml-1"
                style={{ letterSpacing: "-0.1em", padding: "6px 0 0" }}
              >
                {o.label}
              </div>
            </div>
          ))}
        </div>

        {withManualInput && (
          <div className="w-10 ml-1 mr-3 -mt-1 relative -top-0.5">
            <input
              type="number"
              className="text-black input-text text-center text-xxs"
              style={{ padding: "4px 4px", letterSpacing: "-0.1em" }}
              onChange={(e) => {
                if (e.target.value) {
                  onChange(+e.target.value);
                } else {
                  onChange(undefined);
                }
              }}
              value={value}
              onBlur={() => {
                if (!value || value < minValue ) {
                  onChange(minValue);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
