// OrderBox.tsx

import BigNumber from "bignumber.js";
import React, { useState, useEffect, useRef } from "react";
import { eventCloud } from "./EventCloud";
import ConfirmOrderDialog from "./dialogs/ConfirmOrderDialog"; // Import the dialog component
import RiskLimitConfirmDialog from "./dialogs/RiskLimitConfirmDialog"; // Import the dialog component
import "./css/OrderBox.css";

import { ReactComponent as IconMinus } from "./assets/icons/svg/minus.svg";
import { ReactComponent as IconPlus } from "./assets/icons/svg/plus.svg";

import { Asset } from "./interfaces/asset.interface";
import { MarginCurrency } from "./interfaces/marginCurrency.interface";
import { Position } from "./interfaces/position.interface";
import { Order } from "./interfaces/order.interface";
import { ParamsUser } from "./interfaces/paramsUser.interface";
import classNames from "classnames";
import { Button } from "./components/Button/Button";
import { Modal } from "./components/Modal/Modal";
import { SwitchField } from "./components/SwitchField/SwitchField";
import { Balance } from "./interfaces/balance.interface";
import { formatNotificationMessage } from "./helpers/notificationHelpers";
import { Tooltip } from "./components/Tooltip";

import { handleCreateOrder } from "./solidity/CreateOrder";
import { handleChangeRiskLimit } from "./solidity/ChangeRiskLimit";

const OrderBox = () => {
    const [isRiskLimitDialogOpen, setIsRiskLimitDialogOpen] = useState(false)
    const [oldRiskLimit, setOldRiskLimit] = useState(0)
  const [orderType, setOrderType] = useState(
    localStorage.getItem("dmex.orderTypeToggle") || "Market"
  );
  const [amount, setAmount] = useState("");
  const [price, setPrice] = useState("");
  const [assetLastPrice, setAssetLastPrice] = useState("");
  const [stopPrice, setStopPrice] = useState("");
  const [riskLimit, setRiskLimit] = useState<number | undefined>(50);
  const [userMMMultiplier, setUserMMMultiplier] = useState(1);
  const [userFRMultiplier, setUserFRMultiplier] = useState(1);
  const [leverage, setLeverage] = useState(
    localStorage.getItem("dmex.leverageSelector") || "0"
  );
  const [slippage, setSlippage] = useState(
    localStorage.getItem("dmex.maxSlippageSelector") || "0.025"
  );
  const [isReduceOnly, setIsReduceOnly] = useState(
    localStorage.getItem("dmex.isReduceOnlyCheckbox") == "true" || false
  );
  const [isAutoConfirm, setIsAutoConfirm] = useState(
    localStorage.getItem("dmex.isAutoConfirmOrderCheckbox") == "true" || false
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [orderAction, setOrderAction] = useState("");
  const [fundingRate, setFundingRate] = useState("");
  const [positions, setPositions] = useState<Position[]>([]);

  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(
    eventCloud.selectedAsset.getValue()
  );

  const [selectedMarginCurrency, setSelectedMarginCurrency] =
    useState<MarginCurrency | null>(eventCloud.getMarginCurrencyObject());


  const [balances, setBalances] = useState<Record<string, Balance>>({});
  useEffect(() => {
    const subscription = eventCloud.balancesStore.subscribe((newBal) => {
      setBalances(newBal);
    });

    // Clean up the subscription
    return () => subscription.unsubscribe();
  }, []);


  useEffect(() => {
    localStorage.setItem(
      "dmex.isAutoConfirmOrderCheckbox",
      isAutoConfirm.toString()
    );
  }, [isAutoConfirm]);
  useEffect(() => {
    localStorage.setItem("dmex.isReduceOnlyCheckbox", isReduceOnly.toString());
  }, [isReduceOnly]);
  useEffect(() => {
    localStorage.setItem("dmex.maxSlippageSelector", slippage.toString());
  }, [slippage]);
  useEffect(() => {
    localStorage.setItem("dmex.leverageSelector", leverage.toString());
  }, [leverage]);
  // useEffect(() => {
  //   localStorage.setItem("dmex.orderAmountInput", amount.toString());
  // }, [amount]);
  useEffect(() => {
    localStorage.setItem("dmex.orderTypeToggle", orderType.toString());
  }, [orderType]);

  useEffect(() => {
    // Subscribe
    const subscription = eventCloud.positionsStore.subscribe((positions) => {
      if (!positions || positions.length === 0) {
        setPositions([]); // Return early if newAssets is empty
        return;
      }
      setPositions(positions);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (eventCloud.selectedAsset) {
      const assetSubscription = eventCloud.selectedAsset.subscribe((asset) => {
        if (asset) {
          setSelectedAsset(asset);
          // setPrice(
          //   formatPrice(Number(asset.last_price), asset.decimals).toString()
          // );
          // setStopPrice(
          //   formatPrice(Number(asset.last_price), asset.decimals).toString()
          // );
          setFundingRate(asset.funding_rate);
        }
      });

      return () => {
        assetSubscription.unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    const currencySubscription = eventCloud.marginCurrenciesStore.subscribe(
      () => {
        setSelectedMarginCurrency(eventCloud.getMarginCurrencyObject());
      }
    );

    return () => {
      currencySubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (eventCloud.paramsUserStore) {
      const parmasSub = eventCloud.paramsUserStore.subscribe((params) => {
        //console.log("[orderbox] paramsUserStore ", params);
        if (params) {
          setRiskLimit(Number(params.risk_limit));
          setOldRiskLimit(Number(params.risk_limit));
          setUserMMMultiplier(Number(params.mm_multiplier));
          setUserFRMultiplier(Number(params.fr_multiplier));
        }
      });

      return () => {
        parmasSub.unsubscribe();
      };
    }
  }, []);

  useEffect(() => {

    const handleNewTrade = (trade: any) => {
      if (!selectedAsset) return;
      if (trade.Asset == selectedAsset.symbol) {
        //console.log("[OrderBox: handleNewTrade] ", trade);
        setAssetLastPrice((Number(trade.Price) / 1e8).toString());
      }
    };

    const unsubscribeNewTrade = eventCloud.on("newTrade", handleNewTrade);

    const handleNewFundingRate = (fr: any) => {
      console.log("New funding rate", fr, selectedAsset);
      if (!selectedAsset) return;
      if (fr.symbol == selectedAsset.symbol) {
        //console.log("[OrderBox: handleNewFundingRate] ", fr);
        setFundingRate(fr.funding_rate);
      }
    };

    const unsubscribeNewFr = eventCloud.on(
      "newFundingRate",
      handleNewFundingRate
    );

    const handleMarketClose = (position: Position) => {
      console.log("handleMarketClose", position, selectedAsset?.decimals);
      if (!selectedAsset?.notional) return;
      setOrderType("Market");
      setAmount((Number(position.size) / 1e8).toString());
      setIsReduceOnly(true);

      if (position.side) {
        setOrderAction("Sell");
        //(Math.round(Number(e.target.value)/selectedAsset?.notional)*selectedAsset?.notional).toFixed(selectedAsset?.decimals)
        //setPrice((Math.floor(Number(selectedAsset?.last_price) * 0.9/selectedAsset?.notional) * selectedAsset?.notional).toString(selectedAsset?.decimals+2));
        setPrice(
            genMarketPrice(
                Number(assetLastPrice),
                selectedAsset?.notional,
                -Number(slippage),
                selectedAsset?.decimals   
            )
        );
      } else {
        setOrderAction("Buy");
        //setPrice((Math.ceil(Number(selectedAsset?.last_price) * 1.1/selectedAsset?.notional) * selectedAsset?.notional).toString(selectedAsset?.decimals+2));
        setPrice(
            genMarketPrice(
                Number(assetLastPrice),
                selectedAsset?.notional,
                Number(slippage),
                selectedAsset?.decimals   
            )
        );
      }

      setIsDialogOpen(true);
    };

    const unsubscribeMarketClose = eventCloud.on(
      "marketClose",
      handleMarketClose
    );

    interface TakeProfitStopLossDialogProps {
        position: Position;
        price: number;
        amount: number;
        isTakeProfit: boolean;
    }

    const handleTakeProfitStopLoss = (props: TakeProfitStopLossDialogProps) => {
        const { position, price, amount, isTakeProfit } = props;
      console.log("[handleTakeProfitStopLoss]", position, price, amount, isTakeProfit);
      if (!selectedAsset?.notional) return;
      setOrderType("Stop Limit");
      setAmount((Number(position.size) / 1e8).toString());
      setIsReduceOnly(true);

      if (position.side) {
        setOrderAction("Sell");
        setStopPrice(formatPrice(price, position.decimals)+"")
        setPrice(formatPrice(price, position.decimals)+"");
        setAmount(Number(amount/1e8)+"");
      } else {
        setOrderAction("Buy");
        setStopPrice(formatPrice(price, position.decimals)+"")
        setPrice(formatPrice(price, position.decimals)+"");
        setAmount(Number(amount/1e8)+"");
      }

      setIsDialogOpen(true);
    };

    const unsubscribeTakeProfitStopLoss = eventCloud.on(
      "takeProfitStopLoss",
      handleTakeProfitStopLoss
    );

    


    const handleReplaceOrder = (data: any) => {
      const {order, new_price} = data;
      console.log("handleReplaceOrder", order, new_price);
      handleCreateOrder({
        amount: (Number(order.remaining_amount)/1e8).toString(),
        price: new_price,              
        side: order.side,
        leverage: order.leverage.toFixed(0),
        closing_order: order.closing_order,
        stop: order.stop,
        stop_price: order.stop_price,
        base_token: order.base_token,
        is_market: false,
        replace_hash: order.hash,
      });
    };

    const unsubscribeReplaceOrder = eventCloud.on(
      "replaceOrder",
      handleReplaceOrder
    );

    const handlePriceClicked = (price: string) => {
        //console.log(`handlePriceClicked price=${price} orderType=${orderType}`)
      if (orderType != "Market") {
        setPrice((Number(price)/1e8).toString())
      }
    };

    const unsubscribePriceClicked = eventCloud.on(
      "priceClicked",
      handlePriceClicked
    );

    const handleSetOrderboxAmount = (amount: number) => {
      console.log("[handleSetOrderboxAmount]", amount)
      setAmount(amount.toString())
    };

    const unsubscribeSetorderboxAmount = eventCloud.on(
      "setOrderboxAmount",
      handleSetOrderboxAmount
    );

    return () => {
      unsubscribeNewTrade();
      unsubscribeNewFr();
      unsubscribeMarketClose();
      unsubscribeTakeProfitStopLoss();
      unsubscribeReplaceOrder();
      unsubscribePriceClicked();
      unsubscribeSetorderboxAmount();
    };
  }, [selectedAsset, orderType]);

  const prevSelectedAssetRef = useRef<Asset | null>(null);
  useEffect(() => {
    if (!selectedAsset) return;
    if (prevSelectedAssetRef.current && selectedAsset.symbol !== prevSelectedAssetRef.current.symbol) {
        //setAmount("");
        setPrice("");
    }

    prevSelectedAssetRef.current = selectedAsset;
  }, [selectedAsset]);

  useEffect(() => {
    if (orderType != "Market") {
        setPrice(assetLastPrice)
      }
  }, [orderType]);


    // useEffect(() => {
    //     if (price == "") {
    //         setPrice(assetLastPrice)
    //     }
    // }, [assetLastPrice]);

  const [nextFundingTime, setNextFundingTime] = useState("");
  useEffect(() => {
      const interval = setInterval(() => {
          const now = new Date();
          const nextHour = new Date(now);
          nextHour.setHours(now.getHours() + 1);
          nextHour.setMinutes(0);
          nextHour.setSeconds(0);

          const diff = nextHour.getTime() - now.getTime();
          const minutes = Math.floor(diff / 1000 / 60);
          const seconds = Math.floor((diff / 1000) % 60);

          setNextFundingTime(`${minutes.toString().padStart(2, '0')}M ${seconds.toString().padStart(2, '0')}S`);
      }, 1000);

      return () => clearInterval(interval);
  }, []);

  if (!selectedAsset) return null;
  const handleBuySellClick = (action: any) => {
    // Convert string values to numbers for comparison
    const numericAmount = Number(amount);
    const stringPrice = orderType != "Market"
                            ? price
                            : action == "Buy"
                            ? genMarketPrice(
                                Number(assetLastPrice),
                                selectedAsset?.notional,
                                Number(slippage),
                                selectedAsset?.decimals   
                            )
                            : genMarketPrice(
                                Number(assetLastPrice),
                                selectedAsset?.notional,
                                Number(-slippage),
                                selectedAsset?.decimals   
                            );

    // Check if amount and price are greater than zero
    if (numericAmount > 0 && Number(stringPrice) > 0) {
        if (orderType == "Stop Limit" && Number(stopPrice) <= 0) {
            eventCloud.notify(
                formatNotificationMessage({
                  title: "TRIGGER PRICE MUST BE GREATER THAN ZERO",
                  message: [
                    // {
                    //   text: "AMOUNT AND PRICE MUST BE GREATER THAN ZERO",
                    //   bold: false,
                    // },
                  ],
                }),
                "error",
                "2500"
              );

            return;
        }

      setOrderAction(action);

      if (!isAutoConfirm) {
        setIsDialogOpen(true);
      } else {
        try {
          if (!selectedMarginCurrency) return;
          handleCreateOrder({
            amount,
            price: stringPrice,              
            side: action == "Buy" ? true : false,
            leverage: (Number(leverage) * 1e8).toString(),
            closing_order: isReduceOnly,
            stop: orderType == "Stop Limit" ? true : false,
            stop_price:
              orderType != "Stop Limit"
                ? "0"
                : stopPrice == ""
                ? "0"
                : stopPrice,
            base_token: selectedMarginCurrency?.token_address,
            is_market: orderType == "Market",
            replace_hash: "",
          });
        } catch (error) {
          console.error("[handleBuySellClick] Create order failed:", error);
        }
      }
    } else {
      // Handle the error case, e.g., show an alert or set an error state
        if (numericAmount <= 0) {
            eventCloud.notify(
                formatNotificationMessage({
                  title: "AMOUNT MUST BE GREATER THAN ZERO",
                  message: [
                    // {
                    //   text: "AMOUNT AND PRICE MUST BE GREATER THAN ZERO",
                    //   bold: false,
                    // },
                  ],
                }),
                "error",
                "2500"
              );
        }

        if (Number(stringPrice) <= 0) {
            eventCloud.notify(
                formatNotificationMessage({
                  title: "PRICE MUST BE GREATER THAN ZERO",
                  message: [
                    // {
                    //   text: "AMOUNT AND PRICE MUST BE GREATER THAN ZERO",
                    //   bold: false,
                    // },
                  ],
                }),
                "error",
                "2500"
              );
        }


        
      
    }
  };

  const handleBalancePct = (pct: number) => {
    if (!selectedMarginCurrency) return;
    if (!balances[selectedMarginCurrency?.token_address]) return;
    const balance  = getAvailableBalance();
    const balPct = balance*(pct/100);

    const usdBal = balPct / Number(calcMultiplier(Number(selectedMarginCurrency?.mark_price))) /1e10;

    const lev = Number(leverage) > 0 ? Number(leverage) : 1000;

    const p = orderType == "Market" ? (Number(selectedAsset?.last_price) / 1e8) *
                    (100 + Number(slippage))/100 : Number(price);
    let amount = usdBal * lev / p;

    // Round down to the nearest selectedAsset.amount_notional
    const notional = selectedAsset?.amount_notional;
    if (notional) {
        amount = Math.floor(amount / notional) * notional;
    }

    setAmount(amount.toFixed(selectedAsset?.amount_dec));
    console.log(`[handleBalancePct] pct=${pct} balance=${balance} balPct=${balPct} usdBal=${usdBal} amount=${amount} price=${price}`);
  }

  const handleConfirm = () => {
    // Handle order confirmation logic here
    setIsDialogOpen(false);
    setIsReduceOnly(false);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    setIsReduceOnly(false);
  };

    const handleRiskLimitChange = (newRiskLimit?: number) => {   
        if (!newRiskLimit) return;
        if (Number(newRiskLimit) == Number(setOldRiskLimit)) return;
        setOldRiskLimit(Number(riskLimit))
        setRiskLimit(newRiskLimit)
        setIsRiskLimitDialogOpen(true)
    };

    const handleRiskLimitDialogConfirm = async () => {
        var result = await handleChangeRiskLimit(String(riskLimit));
        if (result) {
            setRiskLimit(riskLimit);
            setIsRiskLimitDialogOpen(false);
        }
    }

    const handleRiskLimitDialogClose = () => {
        setRiskLimit(Number(oldRiskLimit))
        setIsRiskLimitDialogOpen(false)
    }

  const getAvailableBalance = (): number => {
    if (!selectedMarginCurrency || !balances[selectedMarginCurrency?.token_address]) return 0;
    return Number(balances[selectedMarginCurrency?.token_address].available_balance) - Number(balances[selectedMarginCurrency?.token_address].crossmargin_reserve);
  }

  const base_token = eventCloud.selectedMarginCurrency;
  const currency = eventCloud.getMarginCurrencyObject();

  return (
    <div className="relative ">
      <div className="flex bg-black bg-opacity-30 border-b border-gray-650 text-xs text-gray-450 sticky top-0 z-10">
        <input
          type="radio"
          id="market"
          name="orderType"
          value="Market"
          checked={orderType === "Market"}
          onChange={() => setOrderType("Market")}
          className="invisible absolute"
        />
        <label
          htmlFor="market"
          className={classNames(
            "px-2 pt-2 pb-1 box-border border-r flex-1 border-gray-650 cursor-pointer text-center bg-black hover:bg-opacity-10 hover:bg-white ",
            {
              "text-white border-b-2 border-b-white": orderType === "Market",
            }
          )}
        >
          <div className="pb-0.5">Market</div>
        </label>

        <input
          type="radio"
          id="limit"
          name="orderType"
          value="Limit"
          checked={orderType === "Limit"}
          onChange={() => setOrderType("Limit")}
          className="invisible absolute"
        />
        <label
          htmlFor="limit"
          className={classNames(
            "px-2 pt-2 pb-1 box-border flex-1 border-r border-gray-650 cursor-pointer text-center bg-black hover:bg-opacity-10 hover:bg-white ",
            {
              "text-white border-b-2 border-b-white": orderType === "Limit",
            }
          )}
        >
          <div className="pb-0.5">Limit</div>
        </label>

        <input
          type="radio"
          id="stopLimit"
          name="orderType"
          value="Stop Limit"
          checked={orderType === "Stop Limit"}
          onChange={() => setOrderType("Stop Limit")}
          className="invisible absolute"
        />
        <label
          htmlFor="stopLimit"
          className={classNames(
            "px-2 pt-2 pb-1 box-border flex-1 cursor-pointer text-center bg-black hover:bg-opacity-10 hover:bg-white ",
            {
              "text-white border-b-2 border-b-white":
                orderType === "Stop Limit",
            }
          )}
          style={{ minWidth: "110px" }}
        >
          <div className="pb-0.5">Stop Limit</div>
        </label>
      </div>

      <div className="pt-3 px-3 text-xs">
        <div>
          <label htmlFor="amount" className="text-xxs text-gray-150 flex justify-between items-center">
            <span className="mb-1 mt-1">Amount:</span>
            {Number(leverage) > 0 && (
                <div className="flex justify-between items-center text-xxs text-gray-450">
                    <button type="button" className='orderbox-amount-percentage-button' onClick={() => { handleBalancePct(25) }}>25%</button>
                    <button type="button" className='orderbox-amount-percentage-button' onClick={() => { handleBalancePct(50) }}>50%</button>
                    <button type="button" className='orderbox-amount-percentage-button' onClick={() => { handleBalancePct(75) }}>75%</button>
                    <button type="button" className='orderbox-amount-percentage-button' onClick={() => { handleBalancePct(100) }}>100%</button>
                </div>
            )}

            {Number(leverage) == 0 && (
                <div className="flex justify-between items-center text-xxs text-gray-450">
                    
                    <button type="button" className='orderbox-amount-percentage-button' onClick={() => { handleBalancePct(1) }}>10X</button>
                    {/*<button type="button" className='orderbox-amount-percentage-button' onClick={() => { handleBalancePct(2.5) }}>25X</button>*/}
                    <button type="button" className='orderbox-amount-percentage-button' onClick={() => { handleBalancePct(5) }}>50X</button>
                    <button type="button" className='orderbox-amount-percentage-button' onClick={() => { handleBalancePct(10) }}>100X</button>
                    <button type="button" className='orderbox-amount-percentage-button' onClick={() => { handleBalancePct(25) }}>250X</button>
                </div>
            )}
            
          </label>
          <div className="flex justify-between relative">
            <input
              type="number"
              id="amount"
              autoComplete="off"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="text-black input-text"
              placeholder="0.00"
              style={{ paddingRight: "96px" }}
            />

            <div className="flex absolute items-center right-px top-px orderbox-amount-buttons">
              <div className="px-2 text-xxs border-l border-gray-650 text-gray-450 h-3 flex items-center">
                {selectedAsset?.symbol.replace(/usd/g, "")}
              </div>
              <div className="flex items-center border-l border-gray-650 bg-gray-730">
                <button
                  type="button"
                  className="orderbox-amount-button text-gray-150 hover:text-white hover:bg-white hover:bg-opacity-5"
                  onClick={() => {
                    if (+amount > selectedAsset?.amount_notional) {
                      setAmount((+amount - selectedAsset?.amount_notional).toFixed(selectedAsset?.amount_dec))
                    }
                  }}
                >
                  <IconMinus className="w-2" />
                </button>
                <div className="h-3 border-l border-gray-650" />
                <button
                  type="button"
                  className="orderbox-amount-button text-gray-150 hover:text-white hover:bg-white hover:bg-opacity-5"
                  onClick={() => {
                    setAmount((+amount + selectedAsset?.amount_notional).toFixed(selectedAsset?.amount_dec))
                  }}
                >
                  <IconPlus className="w-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {orderType != "Market" && (
          <div className="mt-2">
            <label htmlFor="price" className="text-xxs text-gray-150">
              Price:
            </label>
            <div className="flex justify-between relative">
             <input
              type="number"
              id="price"
              autoComplete="off"
              value={price}
              //onChange={(e) => setPrice((Math.round(Number(e.target.value)/selectedAsset?.notional)*selectedAsset?.notional).toFixed(selectedAsset?.decimals))}
              onChange={(e) => setPrice(e.target.value)}
              className="text-black input-text"
            />

            <div className="flex absolute items-center right-px top-px orderbox-amount-buttons">
              <div className="px-2 text-xxs border-l border-gray-650 text-gray-450 h-3 flex items-center">
                USD
              </div>
              <div className="flex items-center border-l border-gray-650 bg-gray-730">
                <button
                  type="button"
                  className="orderbox-amount-button text-gray-150 hover:text-white hover:bg-white hover:bg-opacity-5"
                  onClick={() => {
                    if (+price > selectedAsset?.notional) {
                      setPrice((Math.floor((+price - selectedAsset?.notional)/selectedAsset?.notional)*selectedAsset?.notional).toFixed(selectedAsset?.decimals))
                    }
                  }}
                >
                  <IconMinus className="w-2" />
                </button>
                <div className="h-3 border-l border-gray-650" />
                <button
                  type="button"
                  className="orderbox-amount-button text-gray-150 hover:text-white hover:bg-white hover:bg-opacity-5"
                  onClick={() => {
                    setPrice((Math.floor((+price + selectedAsset?.notional)/selectedAsset?.notional)*selectedAsset?.notional).toFixed(selectedAsset?.decimals))
                  }}
                >
                  <IconPlus className="w-2" />
                </button>
              </div>
            </div>

            </div>

          </div>
        )}

        

        {orderType == "Stop Limit" && (

          <div className="mt-2">
            <label htmlFor="price" className="text-xxs text-gray-150">
              Trigger Price:
            </label>
            <div className="flex justify-between relative">
             <input
              type="number"
              id="stopPrice"
              autoComplete="off"
              value={stopPrice}
              //onChange={(e) => setStopPrice((Math.round(Number(e.target.value)/selectedAsset?.notional)*selectedAsset?.notional).toFixed(selectedAsset?.decimals))}
              onChange={(e) => setStopPrice(e.target.value)}
              
              className="text-black input-text"
            />

            <div className="flex absolute items-center right-px top-px orderbox-amount-buttons">
              <div className="px-2 text-xxs border-l border-gray-650 text-gray-450 h-3 flex items-center">
                USD
              </div>
              <div className="flex items-center border-l border-gray-650 bg-gray-730">
                <button
                  type="button"
                  className="orderbox-amount-button text-gray-150 hover:text-white hover:bg-white hover:bg-opacity-5"
                  onClick={() => {
                    if (+stopPrice > selectedAsset?.notional) {
                      setStopPrice((Math.floor((+stopPrice - selectedAsset?.notional)/selectedAsset?.notional)*selectedAsset?.notional).toFixed(selectedAsset?.decimals))
                    }
                  }}
                >
                  <IconMinus className="w-2" />
                </button>
                <div className="h-3 border-l border-gray-650" />
                <button
                  type="button"
                  className="orderbox-amount-button text-gray-150 hover:text-white hover:bg-white hover:bg-opacity-5"
                  onClick={() => {
                    setStopPrice((Math.floor((+stopPrice + selectedAsset?.notional)/selectedAsset?.notional)*selectedAsset?.notional).toFixed(selectedAsset?.decimals))
                  }}
                >
                  <IconPlus className="w-2" />
                </button>
              </div>
            </div>

            </div>

          </div>
        )}

        <div className="mt-4">
          <div className="flex justify-between text-gray-150 text-xxs">
            <div className="text-gray-450">Order Value:</div>
            {formatNumLocale(Number(formatOrderValue(
              orderType == "Market" ? Number(assetLastPrice) : Number(price),
              Number(amount)
            )), 2)}{" "}
            USD
          </div>
          <div className="flex justify-between text-gray-150 text-xxs">
            <div className="text-xxs text-gray-450">Margin:</div>
            {Number(leverage) > 0 && (formatMargin(
              orderType == "Market" ? Number(assetLastPrice) : Number(price),
              Number(amount),
              Number(leverage),
              Number(selectedMarginCurrency?.mark_price),
              Number(selectedMarginCurrency?.decimals)
            )+ " "+selectedMarginCurrency?.symbol)}

            {Number(leverage) == 0 && (<span>[CROSS]</span>)}
          </div>
          
        </div>

        
        {orderType == "Market" && !true && (
            <div className="mt-4 mb-2">
              <SwitchField
                label="Max Slippage [%]"
                value={+slippage}
                onChange={(v) => {
                  setSlippage(`${v}`);
                }}
                options={[
                  { value: 0.01, label: "0.01" },
                  { value: 0.025, label: "0.025" },
                  { value: 0.05, label: "0.05" },
                  { value: 0.1, label: "0.1" },
                  { value: 0.5, label: "0.5" },
                  { value: 1, label: "1.0" },
                  { value: 2.5, label: "2.5" },
                ]}
                
                minValue={0.01}
              />
            </div>
        )}
        <div className="mt-2 mb-2">
          <SwitchField
            label="Leverage [X]"
            value={+leverage}
            onChange={(v) => {
              setLeverage(`${v}`);
            }}
            options={[
              { value: 0, label: "CROSS" },
              { value: 3, label: "3x" },
              { value: 5, label: "5x" },
              { value: 10, label: "10x" },
              // { value: 25, label: "25x" },
              { value: 50, label: "50x" },
              { value: 100, label: "100x" },
              { value: 250, label: "250x" },
              { value: 500, label: "500x" },
            ]}
            minValue={3}
          />
        </div>

        <div className="flex justify-between my-3 bg-gray-730 p-1">
          <div className="">
            <input
              type="checkbox"
              id="reduceOnly"
              checked={isReduceOnly}
              onChange={(e) => setIsReduceOnly(e.target.checked)}
              className="invisible absolute"
            />
            <label
              htmlFor="reduceOnly"
              className="flex items-center text-xxs text-gray-150 cursor-pointer"
            >
              <div className="flex items-center justify-center w-3 h-3 border border-gray-150 mr-2 bg-black">
                {isReduceOnly && <div className="w-1.5 h-1.5 bg-gray-150" />}
              </div>
              <Tooltip
                  label="Reduce Only"
                  position="left"
                  text="Reduce existing position"
                />
            </label>
          </div>
          <div className="">
            <input
              type="checkbox"
              id="autoConfirm"
              checked={isAutoConfirm}
              onChange={(e) => setIsAutoConfirm(e.target.checked)}
              className="invisible absolute"
            />
            <label
              htmlFor="autoConfirm"
              className="flex items-center text-xxs text-gray-150 cursor-pointer"
            >
              <div className="flex items-center justify-center w-3 h-3 border border-gray-150 mr-2 bg-black">
                {isAutoConfirm && <div className="w-1.5 h-1.5 bg-gray-150" />}
              </div>
              
              <Tooltip
                  label="Skip Confirm"
                  position="right"
                  text="Skip Confirm Order Popup"
                />
            </label>
          </div>
        </div>

        {/*<div className="mt-2">
          <label htmlFor="riskLimit" className="text-xxs text-gray-150">
            Risk Limit:
          </label>

          <div className="input-select-wrapper">
            <select
              id="riskLimit"
              value={riskLimit}
              onChange={(e) => handleRiskLimitChange(Number(e.target.value))}
              className="text-black input-select"
            >
              <option value="50">50K</option>
              <option value="250">250K</option>
              <option value="500">500K</option>
              <option value="1000">1M</option>
              <option value="5000">5M</option>
              <option value="10000">10M</option>
            </select>
          </div>
        </div>*/}

        <div className="flex mt-2">
          <Button
            variant="green"
            className="w-1/2 mr-1"
            size="large"
            onClick={() => handleBuySellClick("Buy")}
          >
            Buy/Long
          </Button>
          <Button
            variant="red"
            className="w-1/2 ml-1"
            size="large"
            onClick={() => handleBuySellClick("Sell")}
          >
            Sell/Short
          </Button>
        </div>

        <div className="text-xxs mt-4">
          

          <div className="flex justify-between text-gray-150">
            <div className="text-xxs text-gray-450"><Tooltip
                  label="Funding Rate"
                  text="Funding Rate / Hour"
                />
            </div>{" "}
            {formatFundingRate(Number(fundingRate), userFRMultiplier)}%/1h
          </div>

          
          <div className="flex justify-between text-gray-150">
            <div className="text-xxs text-gray-450"><Tooltip
                  label="NEXT FUNDING IN"
                  text="Next Funding Event"
                /></div>{" "}
            {nextFundingTime}
          </div>

          <div className="flex justify-between text-gray-150">
            <div className="text-xxs text-gray-450">Fee [maker/taker]:</div>{" "}
            {formatFee(selectedAsset?.maker_fee)}%/
            {formatFee(selectedAsset?.taker_fee)}%
          </div>
          <div className="flex justify-between text-gray-150 text-xxs">
            <div className="text-xxs text-gray-450"><Tooltip
                  label="ASSET MAINT MARGIN"
                  text="ASSET MAINTENANCE MARGIN"
                /></div>
            {formatMultiplier(Number(selectedAsset?.maintenance_margin))}
          </div>
        </div>

        <div className="my-2 border-t border-gray-650 -mx-3" />

        {/*<div className="text-white text-xs">ACCOUNT PARAMETERS</div>*/}

        <div className="mt-1">
          <SwitchField
            label="Risk Limit [USD]"
            value={riskLimit}
            onChange={handleRiskLimitChange}
            options={[
              // { value: 50, label: "50K" },
              // { value: 250, label: "250K" },
              { value: 500, label: "500K" },
              { value: 1000, label: "1M" },
              { value: 2500, label: "2.5M" },
              { value: 5000, label: "5M" },
              { value: 10000, label: "10M" },
              { value: 50000, label: "50M" },
            ]}
          />
        </div>

        <div className="mt-4 text-xxs">
          <div className="flex justify-between text-gray-150">
            <div className="text-xxs text-gray-450"><Tooltip
                  label="USER OI"
                  text="User Open Interest"
                /></div>
            {formatNumLocale(Number(calcOpenInterest(positions)), 2)} USD
          </div>
          <div className="flex justify-between text-gray-150">
            <div className="text-xxs text-gray-450">
            <Tooltip
                  label="ACCOUNT MM MULTIPLIER"
                  text="Maintenance Margin Multiplier"
                /></div>{" "}
            <span>
                <span className="text-xxxs">x</span>
                {formatMultiplier(userMMMultiplier)}
            </span>
          </div>
          {/*<div className="flex justify-between text-gray-150">
            <div className="text-xxs text-gray-450"><Tooltip
                  label="USER FR MULTIPLIER"
                  text="Funding Rate Multiplier"
                /></div>{" "}
            {formatMultiplier(userFRMultiplier)}
          </div>*/}

          {/*<div className="flex justify-between text-gray-150 text-xxs">
            <div className="text-xxs text-gray-450">LiqPrice:</div>
            {formatLiqPrice(
              Number(price),
              Number(amount),
              Number(leverage),
              Number(selectedAsset?.mark_price),
              Number(leverage),
              Number(selectedAsset?.maintenance_margin),
              Number(selectedMarginCurrency?.decimals),
              true,
              Number(userMMMultiplier),
              eventCloud.params?.global_mm_multiplier
            )}
            /
            {formatLiqPrice(
              Number(price),
              Number(amount),
              Number(leverage),
              Number(selectedAsset?.mark_price),
              Number(leverage),
              Number(selectedAsset?.maintenance_margin),
              Number(selectedMarginCurrency?.decimals),
              false,
              Number(userMMMultiplier),
              eventCloud.params?.global_mm_multiplier
            )}
          </div>*/}

          {/*<div className="my-2 border-t border-gray-650" />

          <div className="text-white text-xs pb-1">GLOBAL PARAMETERS</div>*/}
    
          
          <div className="flex justify-between text-gray-150 text-xxs mb-2">
            <div className="text-xxs text-gray-450"><Tooltip
                  label="GLOBAL MM MULTIPLIER"
                  text="Maintenance Margin Multiplier"
                /></div>
            <span>
                <span className="text-xxxs">x</span>
                {formatMultiplier(eventCloud.params?.global_mm_multiplier)}
            </span>
          </div>    

          {/*<div className="mt-2 border-t border-gray-650 -mx-3" />*/}
        </div>

        <Modal visible={isDialogOpen} onClose={handleCancel} freeToClose>
          <ConfirmOrderDialog
            onClose={handleCancel}
            isOpen={isDialogOpen}
            orderDetails={{
              type: orderType,
              amount,
              price:
                orderType != "Market"
                  ? price
                  : orderAction == "Buy"
                  ? genMarketPrice(
                        Number(assetLastPrice),
                        selectedAsset?.notional,
                        Number(slippage),
                        selectedAsset?.decimals   
                    )

                  : genMarketPrice(
                        Number(assetLastPrice),
                        selectedAsset?.notional,
                        -Number(slippage),
                        selectedAsset?.decimals   
                    )
                ,

              leverage,
              side: orderAction,
              is_market: orderType == "Market",
              base_token: selectedMarginCurrency?.token_address,
              closing_order: isReduceOnly,
              stop_price:
                orderType != "Stop Limit"
                  ? "0"
                  : stopPrice == ""
                  ? "0"
                  : stopPrice,
              formatted_stop_price: formatPrice(
                Number(stopPrice == "" ? "0" : stopPrice) * 1e8,
                selectedAsset?.decimals
              ),
              slippage,
              isAutoConfirm,
              liquidation_price:
                orderAction == "Buy"
                  ? formatNumLocale(Number(formatLiqPrice(
                      orderType == "Market" ? Number(assetLastPrice) : Number(price),
                      Number(amount),
                      Number(leverage),
                      Number(selectedAsset?.mark_price),
                      Number(leverage),
                      Number(selectedAsset?.maintenance_margin),
                      Number(selectedAsset?.decimals),
                      true,
                      Number(userMMMultiplier),
                      eventCloud.params?.global_mm_multiplier,
                      getAvailableBalance(),
                    )),2)
                  : formatNumLocale(Number(formatLiqPrice(
                      orderType == "Market" ? Number(assetLastPrice) : Number(price),
                      Number(amount),
                      Number(leverage),
                      Number(selectedAsset?.mark_price),
                      Number(leverage),
                      Number(selectedAsset?.maintenance_margin),
                      Number(selectedAsset?.decimals),
                      false,
                      Number(userMMMultiplier),
                      eventCloud.params?.global_mm_multiplier,
                      getAvailableBalance(),
                    )),2),
              margin: formatMargin(
                orderType == "Market" ? Number(assetLastPrice) : Number(price),
                Number(amount),
                Number(leverage),
                Number(selectedMarginCurrency?.mark_price),
                Number(selectedMarginCurrency?.decimals)
              ),
              margin_symbol: selectedMarginCurrency?.symbol,
              order_value: formatNumLocale(Number(formatOrderValue(
                orderType == "Market" ? Number(assetLastPrice) : Number(price),
                Number(amount)
              )), 2),
              funding_rate: formatFundingRate(
                Number(selectedAsset?.funding_rate),
                userFRMultiplier
              ),
              formatted_amount:
                formatAmount(Number(amount), selectedAsset?.amount_dec) +
                " " +
                selectedAsset?.symbol.replace(/usd/g, "").toUpperCase(),
            }}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        </Modal>

        <Modal visible={isRiskLimitDialogOpen} onClose={handleRiskLimitDialogClose} freeToClose>
            <RiskLimitConfirmDialog 
                open={isRiskLimitDialogOpen}
                onClose={handleRiskLimitDialogClose}
                onConfirm={handleRiskLimitDialogConfirm}
                newRiskLimit={Number(riskLimit)}
                oldRiskLimit={oldRiskLimit}
            />
        </Modal>
      </div>
    </div>
  );
};

const formatLiqPrice = (
  price: number,
  amount: number,
  leverage: number,
  mark_price: number,
  margin: number,
  asset_maintenance_margin: number,
  decimals: number,
  side: boolean,
  user_mm_multiplier: number,
  global_mm_multiplier: number,
  balance: number,
): string => {
    let collateral = (price * amount) / leverage;
    const maintenance_margin = asset_maintenance_margin * global_mm_multiplier * user_mm_multiplier;
    let coef = maintenance_margin / leverage;
    let movement = collateral / amount;
    
  

  // const global_mm_multiplier = 2;
  // const user_mm_multiplier = 1.05;
  
  //console.log("[formatLiqPrice] ", asset_maintenance_margin, global_mm_multiplier, user_mm_multiplier)
  

  

  
  var liquidationPrice;
  var netLiqPrice;

    if (leverage == 0) {
        collateral = balance/1e18;
        movement = collateral / amount;
        if (!side) {
            liquidationPrice = price + movement * (1-maintenance_margin);
        } else {
            liquidationPrice = max(0, price - movement * (1-maintenance_margin));
        }
    } else {
        if (!side) {
            netLiqPrice = price + movement;
            liquidationPrice = netLiqPrice - min(netLiqPrice, price * coef);
        } else {
            netLiqPrice = price - movement;
            liquidationPrice = netLiqPrice + price * coef;
        }
    }

  
  //console.log(`[formatLiqPrice] leverage=${leverage} price=${price} amount=${amount} collateral=${collateral} maintenance_margin=${maintenance_margin} movement=${movement} liquidationPrice=${liquidationPrice} coef=${coef}`)
  return Number(liquidationPrice).toFixed(decimals);
};

const calcOpenInterest = (positions: Position[]): string => {
  const total = positions.reduce(
    (sum, position) => sum + Number(position.value),
    0
  );
  return (total / 1e8).toFixed(2);
};

const min = (a: number, b: number): number => {
  if (a > b) {
    return b;
  } else {
    return a;
  }
};

const max = (a: number, b: number): number => {
  if (a < b) {
    return b;
  } else {
    return a;
  }
};

const formatMargin = (
  price: number,
  amount: number,
  leverage: number,
  mark_price: number,
  decimals: number
): string => {
  return calcMargin(price, amount, leverage, mark_price).toFixed(decimals);
};

const calcMargin = (
  price: number,
  amount: number,
  leverage: number,
  mark_price: number
): number => {
  const multiplier = Number(calcMultiplier(mark_price));
  return (((price * amount) / leverage) * multiplier) / 1e8;
};

const formatOrderValue = (price: number, amount: number): string => {
  return (price * amount).toFixed(2);
};

const formatFundingRate = (fr: number, usr_mult: number): string => {
  return ((fr / 1e16) * usr_mult).toFixed(6);
};

const formatFee = (fee: any): string => {
  return Number(fee / 1e16).toFixed(3);
};

const formatMultiplier = (multiplier: any): string => {
  if (isNaN(multiplier) || multiplier === undefined) {
    return "0"; // or any default value you wish to return
  }
  return multiplier.toFixed(2);
};

const formatPrice = (price: number, decimals: number): string => {
  return (Number(price) / 1e8).toFixed(decimals);
};

const formatAmount = (amount: number, amount_dec: number): string => {
  return Number(amount).toFixed(amount_dec);
};

const genMarketPrice = (last_price: number, notional: number, slippage: number, decimals: number): string => {
    //console.log(`[genMarketPrice] last_price=${last_price} slippage=${slippage} notional=${notional}`)

    if (slippage < 0) {
        return (Math.floor((last_price * Math.pow(10, decimals) * (1+slippage/100)/notional) * notional)/Math.pow(10, decimals)).toFixed(decimals+2);
    } else {
        return (Math.ceil((last_price * Math.pow(10, decimals) * (1+slippage/100)/notional) * notional)/Math.pow(10, decimals)).toFixed(decimals+2);
    }

    return (Math.round((last_price * Math.pow(10, decimals) * (1+slippage/100)/notional) * notional)/Math.pow(10, decimals)).toFixed(decimals+2);
}

const calcMultiplier = (mark_price: number): string => {
  //console.log("[calcMultiplier] mark_price=", mark_price);
  // Convert markPrice to a BigNumber
  const lastPrice = new BigNumber(mark_price);

  // Divide 1 by lastPrice
  const one = new BigNumber(1);
  let result = one.dividedBy(lastPrice);

  // Multiply result by 1e8
  const tenMillion = new BigNumber(1e8);
  result = result.multipliedBy(tenMillion);

  // Convert result to an integer
  const multiplier = result.integerValue(BigNumber.ROUND_FLOOR);

  //console.log("Multiplier:", multiplier.toString());
  return multiplier.toString();
};

const formatNumLocale = (price: number, decimals: number): string => {
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(price);
};

export default OrderBox;
