// Positions.tsx

import React, { useState, useEffect } from "react";
import { eventCloud } from "./EventCloud";
import { Position } from "./interfaces/position.interface";
import classNames from "classnames";
//import CancelOrderDialog from './dialogs/CancelOrderDialog';
import "./css/Positions.css";

// const pp: Position[] = [];
// for (let i = 0; i < 20; i++) {
//   pp.push({
//     tx_hash: `tx_hash_${i}`,
//     hash: `hash_${i}`,
//     base_token: `base_token_${i}`,
//     asset: `asset_${i}`,
//     side: Math.random() > 0.5,
//     size: `32423252523242325252`,
//     value: `32423252523242325252`,
//     entry_price: `32423252523242325252`,
//     mark_price: Math.random() * 100,
//     last_price: Math.random() * 100,
//     liquidation_price: `liquidation_price_${i}`,
//     leverage: Math.floor(Math.random() * 10) + 1,
//     margin: `margin_${i}`,
//     unrealized_pnl: `unrealized_pnl_${i}`,
//     realized_pnl: `realized_pnl_${i}`,
//     funding_rate: `funding_rate_${i}`,
//     decimals: 2,
//     amount_dec: 2,
//     margin_dec: 2,
//     margin_symbol: `$`,
//     profit: `profit_${i}`,
//     loss: `loss_${i}`,
//     funding_cost: `funding_cost_${i}`,
//     ur_funding_cost: `ur_funding_cost_${i}`,
//     created_at: new Date().toISOString(),
//     liquidated: Math.random() > 0.5,
//   });
// }

const PositionsClosed = () => {
  const [positions, setPositions] = useState<Position[]>([]);

  useEffect(() => {
    // Subscribe
    const subscription = eventCloud.positionsClosedStore.subscribe(
      (positions) => {
        if (!positions || positions.length === 0) {
          setPositions([]); // Return early if newAssets is empty
          return;
        }
        setPositions(positions);
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className="text-xxs positions-block-wrapper">
      <h2 className="px-2 py-2 bg-black bg-opacity-30 border-gray-650 font-medium text-xs table-headline">
        Closed Positions
      </h2>
      <div className="order-table-wrapper container-scroll  text-gray-150">
        <table
          style={{ width: "100%", borderCollapse: "collapse" }}
          className="order-table"
        >
          <thead>
            <tr>
              <th className="text-left">Close Time</th>
              <th className="text-left">Side</th>
              <th className="text-left">Asset</th>

              <th className="text-right">Profit/Loss</th>
              <th className="text-right">Funding Cost</th>
              <th className="text-right">Net PNL</th>
            </tr>
          </thead>
          <tbody>
            {positions.map((position, index) => {
            	const pnl = formatPnL(position);
            	const netpnl = formatAmount(position.liquidated ?
            		Number(pnl)*1e8
            		:
            		Number(pnl)*1e8 - Number(position.funding_cost),
            		position.margin_dec
            	);
             return (
              <tr key={index}>
                <td className="text-left">
                  {formatTimestamp(position.created_at)}
                </td>
                <td className={classNames([
			                position.side
			                  ? "text-green-550 text-left"
			                  : "text-red-550 text-left",
			              ])}>
                  {position.side ? "LONG" : "SHORT"}
                </td>
                <td className="text-left">{position.asset.replace(/usd/g, "").toUpperCase()}</td>
                <td className={classNames([
			                Number(pnl) >= 0
			                  ? "text-green-550 text-right"
			                  : "text-red-550 text-right",
			              ])}>
                  {pnl}{" "}{position.margin_symbol}{" "}
                  [{eventCloud.findMarginCurrency(position.base_token)?.network_slug}]
                </td>
                <td className="text-right text-red-550">
                  -{formatAmount(Number(position.funding_cost)+Number(position.ur_funding_cost), position.margin_dec)}{" "}
                  {position.margin_symbol}{" "}
                  [{eventCloud.findMarginCurrency(position.base_token)?.network_slug}]
                </td>
                <td className={classNames([
			                Number(netpnl) > 0
			                  ? "text-green-550 text-right"
			                  : "text-red-550 text-right",
			              ])}>
                  {netpnl}{" "}{position.margin_symbol}{" "}
                  [{eventCloud.findMarginCurrency(position.base_token)?.network_slug}]
                </td>
              </tr>
              )
            })}
          </tbody>
        </table>

        {positions.length === 0 && (
          <div className="py-3 text-center text-xxs text-gray-450 h-full pb-8 flex justify-center items-center" style={{ height: '80%' }}>No data</div>
        )}
      </div>

      {/* Cancel Order Dialog */}
      {/*<CancelOrderDialog
                open={isCancelDialogOpen}
                onClose={handleCancelClose}
                orderHash={selectedOrderHash}
                onConfirm={handleConfirmCancellation}
            />*/}
    </div>
  );
};

const formatPrice = (price: number, decimals: number): string => {
  return (Number(price) / 1e8).toFixed(decimals);
};

const formatAmount = (amount: number, amount_dec: number): string => {
  return (amount / 1e8).toFixed(amount_dec);
};

const formatPnL = (position: Position): string => {
	if (position.liquidated) {
		return (-Number(position.loss) / 1e8).toFixed(position.margin_dec);
	}

  	var value = Number(position.profit) - Number(position.loss);


  	return (Number(value) / 1e8).toFixed(position.margin_dec);
};

const formatTimestamp = (timestamp: any) => {
  const date = new Date(timestamp);
  return date.toLocaleTimeString([], {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

export default PositionsClosed;
