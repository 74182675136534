// Wallets.tsx

import React, { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import classNames from "classnames";
import "./css/Wallets.css";
import { eventCloud } from "./EventCloud";
import { Wallet } from "./interfaces/wallet.interface";
import { ReactComponent as IconArrow } from "./assets/icons/svg/arrow.svg";
import { Button } from "./components/Button/Button";
import KeyDialog from "./dialogs/KeyDialog";
import ImportDialog from "./dialogs/ImportDialog";
import { Modal } from "./components/Modal/Modal";
import { formatNotificationMessage } from "./helpers/notificationHelpers";
import { toast } from 'react-toastify';

const toShortenedAddress = (a: string) => {
  return `${a.slice(0, 15)}…${a.slice(-4)}`;
};

const Wallets = () => {
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isKeyDialogOpen, setIsKeyDialogOpen] = useState(false);
  const [isImportDialogOpen, setImportDialogOpen] = useState(false);
  const [keyDialogWallet, setKeyDialogWallet] = useState<Wallet | null>(null);

  useEffect(() => {
    const storedWallets = localStorage.getItem("wallets");
    const storedSelectedWallet = localStorage.getItem("selectedWallet");

    if (storedWallets) {
      const walletsArray = JSON.parse(storedWallets);
      setWallets(walletsArray);

      if (storedSelectedWallet) {
        var walletAddy = JSON.parse(storedSelectedWallet);
        setSelectedWallet(walletAddy);
      } else if (walletsArray.length > 0) {
        setSelectedWallet(walletsArray[0]);
      }
    } else {
      generateNewWallet(true);
    }

    const handleOpenKeyDialog = (wallet: Wallet) => {
        setKeyDialogWallet(wallet);
        setIsKeyDialogOpen(true);
    }

    const unsubscribeKeyDialog = eventCloud.on(
      "openKeyDialog",
      handleOpenKeyDialog
    );

    return () => {
        unsubscribeKeyDialog();
    }
  }, []);

  useEffect(() => {
    if (selectedWallet) {
      localStorage.setItem("selectedWallet", JSON.stringify(selectedWallet));
      eventCloud.setSelectedWallet(selectedWallet);
    }
  }, [selectedWallet]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectWallet = (wallet: Wallet) => {
    setSelectedWallet(wallet);
    setDropdownOpen(false);
    localStorage.setItem("selectedWallet", JSON.stringify(wallet));
  };

  const deleteWallet = (address: string) => {
    const updatedWallets = wallets.filter(
      (wallet) => wallet.address !== address
    );
    setWallets(updatedWallets);
    localStorage.setItem("wallets", JSON.stringify(updatedWallets));

    if (selectedWallet && selectedWallet.address === address) {
      setSelectedWallet(null);
      localStorage.removeItem("selectedWallet");
    }
  };

  const handleKeyDialogOpen = (wallet: Wallet) => {
    setKeyDialogWallet(wallet);
    setIsKeyDialogOpen(true);
  };

  const handleKeyDialogClose = () => {
    setKeyDialogWallet(null);
    setIsKeyDialogOpen(false);
  };

  const generateNewWallet = (forced: boolean) => {
    const newWallet = ethers.Wallet.createRandom();
    const walletData = {
      address: newWallet.address,
      privateKey: newWallet.privateKey,
      isMetaMask: false,
    };
    const updatedWallets = [...wallets, walletData];
    setWallets(updatedWallets);
    localStorage.setItem("wallets", JSON.stringify(updatedWallets));
    selectWallet(walletData);

    if (!forced) {
      eventCloud.notify(
        formatNotificationMessage({
          title: "Wallet Created",
          message: [
            {
              text: "New wallet created, Backup private key in the wallet drop down",
              bold: false,
            },
          ],
        }),
        "success",
        "10000"
      );
    } else {

        setTimeout(() => {
            // eventCloud.notify(
            //     formatNotificationMessage({
            //         title: "NO LIQUIDATIONS",
            //         message: [
            //             {
            //                 text: "DMEX does not liquidate positions. When a position reaches its margin call price, it is automatically closed at the market price (AUTO STOP-LOSS).",
            //                 bold: false,
            //             },
            //         ],
            //     }),
            //     "warn",
            //     "40000"
            // );

            // setTimeout(() => {
                eventCloud.notify(
                    formatNotificationMessage({
                        title: "Wallet Generated",
                        message: [
                            {
                                text: "A fresh wallet was generated and stored in your browser.",
                                bold: false,
                            },
                        ],
                    }),
                    "warn",
                    "3000"
                );
            // }, 1000);

            // setTimeout(() => {
            //     eventCloud.notify(
            //         formatNotificationMessage({
            //             title: "Demo Funds",
            //             message: [
            //                 {
            //                     text: "10000 DEMO USD WERE DEPOSITED TO YOUR ACCOUNT",
            //                     bold: false,
            //                 },
            //             ],
            //         }),
            //         "warn",
            //         "60000"
            //     );
            // }, 2000);

            setTimeout(() => {
                // toast.info("Follow our Telegram Channel for updates", {
                //     position: "bottom-right",
                //     autoClose: 6000, // Correct parsing
                //     hideProgressBar: true,
                //     closeOnClick: false,
                //     pauseOnHover: false,
                //     draggable: false,
                //     progress: undefined,
                //     pauseOnFocusLoss: false,
                //     theme: "dark",
                // });
                toast.info(
                    <div>
                        Follow Our <u><b><a href="https://t.me/dmex_official" target="_blank" rel="noopener noreferrer">Telegram Channel</a></b></u> for updates
                    </div>,
                    {
                        position: "bottom-right",
                        autoClose: 8000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        pauseOnFocusLoss: false,
                        theme: "dark",
                    }
                );
            }, 2000);
                
        }, 1000);

        
    }
  };

  const handleImportPrivateKey = () => {
    setImportDialogOpen(true);
  };

  const handleImportDialogClose = () => {
    setImportDialogOpen(false);
  };

    const connectMetaMask = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const account = accounts[0];
                const existingWallet = wallets.find(w => w.address === account);
                if (existingWallet) {
                    selectWallet(existingWallet);
                } else {
                    const walletData = {
                        address: account,
                        privateKey: "", // MetaMask does not expose private keys
                        isMetaMask: true,
                    };
                    setWallets([...wallets, walletData]);
                    localStorage.setItem(
                        "wallets",
                        JSON.stringify([...wallets, walletData])
                    );
                    selectWallet(walletData);
                }
            } catch (error) {
                console.error("Error connecting to MetaMask", error);
            }
        } else {
            console.log("MetaMask is not installed!");
        }
    };

  const walletsRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e: MouseEvent) => {
    if (walletsRef.current && !walletsRef.current.contains(e.target as Node)) {
      setDropdownOpen(false);
    }
  };

  return (
    <div
      className="wallets-dropdown text-xs text-gray-150 w-full"
      ref={walletsRef}
    >
      <div
        className="selected-wallet flex justify-between w-full items-center cursor-pointer py-4 h-12"
        onClick={toggleDropdown}
      >
        <div>
          <span>
            {selectedWallet
              ? toShortenedAddress(selectedWallet.address)
              : "None"}
          </span>
          {selectedWallet?.isMetaMask && (
            <div className="text-xxs text-yellow-550">EXTERNAL WALLET</div>
          )}
          {!selectedWallet?.isMetaMask && (
            <div className="text-xxs text-green-550">Private Key</div>
          )}
        </div>

        <IconArrow className="ml-2 w-3" />
        {/*<button onClick={toggleDropdown}>Change Wallet</button>*/}
      </div>
      {dropdownOpen && (
        <div
          className="dropdown-content -ml-4 box-border"
          style={{ width: "239px" }}
        >
          <ul className="pb-0 pr-2 mb-2 overflow-y-scroll wallets-dropdown-list container-scroll">
            {wallets.map((wallet, index) => (
              <li key={index} className="pb-1">
                <span
                  onClick={() => selectWallet(wallet)}
                  className="cursor-pointer"
                >
                  {toShortenedAddress(wallet.address)}
                </span>
                {wallet.isMetaMask && (
                  <div
                    onClick={() => selectWallet(wallet)}
                    className="text-xxs text-yellow-550 -mt-0.5 cursor-pointer"
                  >
                    EXTERNAL WALLET
                  </div>
                )}
                {!wallet.isMetaMask && (
                  <div
                    onClick={() => selectWallet(wallet)}
                    className="text-xxs text-green-550 -mt-0.5 cursor-pointer"
                  >
                    Private key
                  </div>
                )}

                <div className="flex mt-0.5">
                  <Button
                    variant="gray"
                    size="small"
                    copy={wallet.address}
                    className={classNames("px-2/2 mr-2", [
                      wallet.isMetaMask ? "w-full" : "w-1/2",
                    ])}
                  >
                    Copy
                  </Button>
                  {!wallet.isMetaMask && (
                    <Button
                      variant="gray"
                      size="small"
                      onClick={() => handleKeyDialogOpen(wallet)}
                      className=" w-1/2 px-2 mr-2"
                    >
                      Key
                    </Button>
                  )}
                  <Button
                    variant="gray"
                    size="small"
                    onClick={() => deleteWallet(wallet.address)}
                    className="px-2"
                  >
                    X
                  </Button>
                </div>
              </li>
            ))}
          </ul>

          <div className="border-t pt-4 border-gray-650 px-4">
            <Button
              variant="yellow"
              className="w-full mb-2"
              size="large"
              onClick={() => {generateNewWallet(false)}}
            >
              Generate New Wallet
            </Button>
            <Button
              variant="yellow"
              className="w-full mb-2"
              size="large"
              onClick={handleImportPrivateKey}
            >
              Import Private Key
            </Button>
            <Button
              variant="yellow"
              className="w-full mb-2"
              size="large"
              onClick={connectMetaMask}
            >
              Connect MetaMask
            </Button>
            <Button
              variant="yellow"
              className="w-full"
              size="large"
              onClick={connectMetaMask}
            >
              Connect Coinbase
            </Button>
          </div>
        </div>
      )}

      {/* Cancel Order Dialog */}

      <Modal
        visible={isKeyDialogOpen}
        onClose={handleKeyDialogClose}
        freeToClose
      >
        <KeyDialog wallet={keyDialogWallet} onClose={handleKeyDialogClose} />
      </Modal>

      <Modal
        visible={isImportDialogOpen}
        onClose={handleImportDialogClose}
        freeToClose
      >
        <ImportDialog
          wallets={wallets}
          onClose={handleImportDialogClose}
          setWallets={setWallets}
          selectWallet={selectWallet}
        />
      </Modal>
    </div>
  );
};

export default Wallets;
